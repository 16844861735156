/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { FC, PropsWithChildren } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Carousel } from '@/components';
import skinnedLogo from '@/assets/svg/careitator-skin-logo.svg';
import defaultLogo from '@/assets/svg/logo.svg';

type AuthLayoutProps = PropsWithChildren;

const Title = tw.h2`mb-9 bg-gradient-to-r from-white bg-clip-text font-semibold text-transparent text-6xl to-[#C8E8F5] to-[140%] from-[-15%] leading-[80px]`;

const CarouselItem = ({ title, description }: any) => (
  <div tw="flex h-full flex-col justify-center">
    <img src={defaultLogo} alt="Default logo" tw="mb-24 h-20 w-20" />
    <Title>{title}</Title>
    <p tw="text-base text-white">{description}</p>
  </div>
);

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => (
  <div tw="grid h-full grid-cols-2 min-w-[1440px]">
    <div tw="my-auto flex h-full flex-col">
      <div tw="flex h-full flex-col items-start justify-center justify-items-start px-36 py-9">
        <div tw="mt-auto flex w-full flex-col items-start justify-center justify-items-start max-w-[470px]">
          {children || <Outlet />}
        </div>
        <p tw="mt-auto text-[#B3B3B3]">
          By creating an account, you agree to our{' '}
          <a
            href="https://www.careitator.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
            tw="text-primary underline hover:font-medium"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href="https://www.careitator.com/terms-of-service"
            target="_blank"
            rel="noreferrer"
            tw="text-primary underline hover:font-medium"
          >
            Terms and Conditions.
          </a>
        </p>
      </div>
    </div>

    <div tw="fixed top-0 right-0 h-full w-1/2 overflow-hidden bg-primary-800">
      <img src={skinnedLogo} alt="Skinned Logo" tw="absolute -top-6 -right-8" />

      <Carousel loop dotType="outlined" tw="mx-24 h-screen">
        <CarouselItem
          title="Effortless & Reliable Staffing Solutions"
          description="Find skilled professionals quickly, avoiding shift vacancies and maintaining optimal patient care levels"
        />
        <CarouselItem
          title="Quality Care with Skilled Professionals"
          description="Access a vast network of skilled professionals on CareItator, guaranteeing that your facility consistently delivers top-notch care to your patients."
        />
        <CarouselItem
          title="Unlocking New Efficiency Horizons"
          description="Cut administrative overhead and reduce operational costs with CareItator's efficient scheduling and payment systems, letting you focus on what matters most—patient care."
        />
      </Carousel>
    </div>
  </div>
);

AuthLayout.propTypes = {};

export default AuthLayout;
