import axios from 'axios';
import { storage } from '.';
import globalRouter from './global-router';
import { RoutePath } from '@/lib/constants/route-path';

const axiosClient = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axiosClient.interceptors.request.use(
  config => {
    const token = storage.getToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  response => {
    const { status, data } = response;
    if (status === 401) {
    }
    return response;
  },
  error => {
    if (error.response?.status === 401 && globalRouter.navigate) {
      globalRouter.navigate(RoutePath.login);
    }

    return Promise.reject(error);
  },
);

export default axiosClient;
