import React, { useEffect } from 'react';
import get from 'lodash.get';
import {
  RegisterOptions,
  DeepMap,
  FieldError,
  UseFormRegister,
  Path,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import TimeInput, { TimeInputProps } from '@/components/time-input';

export type FormTimeInputProps<TFormValues> = {
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  register?: UseFormRegister<any>;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
} & Omit<TimeInputProps, 'name'>;

const FormTimeInput = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  onChange,
  step,
  onFocus,
  onBlur,
  ...props
}: FormTimeInputProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div aria-live="polite">
      <TimeInput
        name={name}
        aria-invalid={hasError}
        hasError={hasError}
        required={!!rules?.required}
        step={step}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        {...props}
        {...(register && register(name, rules))}
      />
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name as any}
          render={({ message }) => (
            <p className="mt-1 text-left text-xs text-red-600">{message}</p>
          )}
        />
      )}
    </div>
  );
};

export default FormTimeInput;
