/** @jsxImportSource @emotion/react */
import { ChangeEventHandler, useState, FC, forwardRef } from 'react';
import tw from 'twin.macro';
import { Spinner } from 'flowbite-react';

export type ShiftTextAreaProps = {
  id?: string;
  name?: string;
  required?: boolean;
  loading?: boolean;
  placeholder?: string;
  value?: string;
  hasError?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
};

const ShiftTextArea: FC<ShiftTextAreaProps> = forwardRef<
  HTMLTextAreaElement,
  ShiftTextAreaProps
>(
  (
    {
      id,
      name,
      required,
      hasError,
      value,
      disabled,
      loading,
      placeholder,
      onChange,
      readOnly,
      ...props
    },
    ref,
  ) => {
    const [focus, setFocus] = useState(false);

    return (
      <div tw="relative w-full">
        <textarea
          ref={ref}
          id={id}
          name={name}
          placeholder={placeholder}
          tw="w-full resize-none border-none font-normal leading-normal h-[100px] text-[13px] text-[#828282] rounded-[6px] focus:(outline-none ring-[#00aeef])"
          css={[
            !value && !focus && tw`border-none bg-white`,
            hasError &&
              tw`animate-shake border-red-600 bg-red-50 outline-red-600 focus:(border-red-600 ring-red-500)`,
            readOnly && tw`cursor-not-allowed`,
          ]}
          disabled={disabled}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          {...props}
        />

        {loading && (
          <div tw="absolute inset-y-0 right-0 z-10 flex cursor-pointer items-center pr-4">
            <Spinner size="md" color="info" aria-label="Loading spinner" />
          </div>
        )}
      </div>
    );
  },
);

export default ShiftTextArea;
