import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Facilities, Licenses, ShiftCategory } from './types';

type AuthState = {
  otpPrefix: string;
  setOtpPrefix: (otpPrefix: string) => void;
  clearOtpPrefix: () => void;

  email: string;
  setEmail: (email: string) => void;
  clearEmail: () => void;

  facilities: Facilities;
  currentFacility: any;
  setFacilities: (facilities: Facilities) => void;
  setCurrentFacility: (currentFacility: any) => void;
  clearFacilities: () => void;
  clearCurrentFacility: () => void;

  licenses: Licenses;
  setLicenses: (licenses: Licenses) => void;
  clearLicenses: () => void;

  shiftCategory: ShiftCategory;
  setShiftCategory: (shiftCategory: ShiftCategory) => void;
  clearShiftCategory: () => void;
};

const useAuthStore = create<AuthState>()(
  persist(
    set => ({
      otpPrefix: '',
      setOtpPrefix: (otpPrefix: string) => set(() => ({ otpPrefix })),
      clearOtpPrefix: () => set(() => ({ otpPrefix: '' })),

      email: '',
      setEmail: (email: string) => set(() => ({ email })),
      clearEmail: () => set(() => ({ email: '' })),

      facilities: {} as Facilities,
      currentFacility: {},
      setFacilities: (facilities: Facilities) => set(() => ({ facilities })),
      setCurrentFacility: (currentFacility: any) =>
        set(() => ({ currentFacility })),
      clearFacilities: () => set(() => ({ facilities: {} as Facilities })),
      clearCurrentFacility: () => set(() => ({ currentFacility: {} })),

      licenses: [] as Licenses,
      setLicenses: (licenses: Licenses) => set(() => ({ licenses })),
      clearLicenses: () => set(() => ({ licenses: [] as Licenses })),

      shiftCategory: [] as ShiftCategory,
      setShiftCategory: (shiftCategory: ShiftCategory) =>
        set(() => ({ shiftCategory })),
      clearShiftCategory: () =>
        set(() => ({ shiftCategory: [] as ShiftCategory })),
    }),
    {
      name: 'auth-store',
    },
  ),
);

export default useAuthStore;
