/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import logo from '@/assets/svg/logo-full.svg';
import iconDashbord from '@/assets/svg/home.svg';
import iconDashbordFilled from '@/assets/svg/home-filled.svg';
import iconBooking from '@/assets/svg/calendar-check.svg';
import iconBookingFilled from '@/assets/svg/calendar-check-filled.svg';
import iconShiftManagement from '@/assets/svg/folder-plus.svg';
import iconShiftManagementFilled from '@/assets/svg/folder-plus-filled.svg';
import iconTimeSheet from '@/assets/svg/scan.svg';
import iconTimeSheetFilled from '@/assets/svg/scan-filled.png';
import iconAccount from '@/assets/svg/user.svg';
import iconAccountFilled from '@/assets/svg/user-filled.svg';
import iconSupport from '@/assets/svg/headset.svg';
import iconSupportFilled from '@/assets/svg/headset-filled.png';
import iconInvoicing from '@/assets/svg/coin-vertical.svg';
import iconInvoicingFilled from '@/assets/svg/coin-vertical-filled.png';
import iconSecurity from '@/assets/svg/lock.svg';
import iconSecurityFilled from '@/assets/svg/lock.svg';
import iconLogout from '@/assets/svg/sign-out.svg';
import { LogoutLink, MenuLink } from './components';
import { useAuthGuard } from '@/lib/hooks/use-auth-guard';
import TaskInfoModal from "@/views/shift-management/modals/taskinfo-modal";
import LaunchInfoModal from "@/layouts/main-layout/launchinfo-modal";

type MainLayoutProps = PropsWithChildren;

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  useAuthGuard();

  return (
    <div tw="flex overflow-hidden min-w-[1440px]">
      <div tw="flex h-screen flex-col items-start overflow-y-auto bg-primary-800 pl-6 pt-8 pb-24 min-w-[20rem]">
        <img src={logo} alt="Careitator logo" tw="w-auto h-[38px]" />
        <div tw="mt-10 mb-12 border-sapphire border-b w-[calc(100%-1.5rem)]" />
        <MenuLink
          to="/dashboard"
          label="Dashboard"
          icon={iconDashbord}
          activeIcon={iconDashbordFilled}
          active={true}
        />
        <MenuLink
          to="/bookings"
          label="Bookings"
          icon={iconBooking}
          activeIcon={iconBookingFilled}
          active={false}
        />
        <MenuLink
          to="/shift-management"
          label="Shift Management"
          icon={iconShiftManagement}
          activeIcon={iconShiftManagementFilled}
          active={false}
        />
        <MenuLink
          to="/timesheet"
          label="Timesheet"
          icon={iconTimeSheet}
          activeIcon={iconTimeSheetFilled}
          active={false}
        />
        <MenuLink
          to="/account"
          label="Account"
          icon={iconAccount}
          activeIcon={iconAccountFilled}
          active={false}
        />
        <MenuLink
          to="/support"
          label="Support"
          icon={iconSupport}
          activeIcon={iconSupportFilled}
          active={false}
        />

        <LogoutLink label="Logout" icon={iconLogout} />
      </div>

      <div tw="flex h-screen w-full flex-col items-start justify-items-start overflow-auto px-8 pt-5 bg-[#fafafa]">
        {children || <Outlet />}
        {/*{process.env.REACT_APP_ENV_PRELAUNCH == "true" ?*/}
        {/*    <LaunchInfoModal*/}
        {/*        openTaskModal="pop-up"*/}
        {/*        setOpenTaskModal={() => undefined}*/}
        {/*        setTaskEdit={true}*/}
        {/*    />: ""}*/}

      </div>
    </div>
  );
};

export default MainLayout;
