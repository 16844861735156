/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import skinnedLogo from '@/assets/svg/careitator-skin-logo.svg';
import defaultLogo from '@/assets/svg/logo.svg';
import Carousel from '../carousel';

const Title = tw.h2`mb-9 bg-gradient-to-r from-white bg-clip-text font-semibold text-transparent text-6xl to-[#C8E8F5] to-[140%] from-[-15%] leading-[80px]`;

const AuthCarousel = () => (
  <div tw="relative h-screen bg-primary-800">
    <img src={skinnedLogo} alt="Skinned Logo" tw="absolute -top-6 -right-8" />

    <Carousel loop dotType="outlined" tw="mx-24 h-screen">
      <div tw="flex h-full w-full flex-col justify-center">
        <img
          src={defaultLogo}
          alt="Default logo"
          tw="mb-24 h-[74px] w-[74px]"
        />
        <Title>Effortless & Reliable Staffing Solutions</Title>
        <p tw="text-base text-white">
          Find skilled professionals quickly, avoiding shift vacancies and
          maintaining optimal patient care levels
        </p>
      </div>
      <div tw="flex h-full flex-col justify-center">
        <img src={defaultLogo} alt="Default logo" tw="mb-24 h-20 w-20" />
        <Title>Lorem ipsum dolor sit amet</Title>
        <p tw="text-base text-white">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi architecto beatae vitae
        </p>
      </div>
      <div tw="flex h-full flex-col justify-center">
        <img src={defaultLogo} alt="Default logo" tw="mb-24 h-20 w-20" />
        <Title>Ut enim ad minima veniam</Title>
        <p tw="text-base text-white">
          At vero eos et accusamus et iusto odio dignissimos ducimus qui
          blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
          et quas molestias excepturi sint occaecati cupiditate non provident,
          similique sunt in culpa qui officia deserunt mollitia animi, id est
          laborum et dolorum fuga.
        </p>
      </div>
    </Carousel>
  </div>
);

export default AuthCarousel;
