/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

type Props = {
  itemsLength: number;
  selectedIndex: number;
  type?: 'outlined' | 'default';
};

const Dots = ({ itemsLength, selectedIndex, type }: Props) => {
  const arr = new Array(itemsLength).fill(0);
  return (
    <div tw="absolute bottom-4 flex justify-center gap-2 left-[calc(50%-23px)] sm:bottom-11">
      {arr.map((_, index) => {
        const selected = index === selectedIndex;
        return (
          <div
            css={
              type === 'outlined'
                ? [
                    tw`h-2.5 w-2.5 rounded-full border-primary border bg-transparent transition-all duration-300`,
                    selected && tw`!bg-primary`,
                  ]
                : [
                    tw`h-2.5 w-2.5 rounded-full bg-primary-800 transition-all duration-300`,
                    !selected && tw`!bg-[#D9D9D9]`,
                  ]
            }
            key={index}
          ></div>
        );
      })}
    </div>
  );
};
export default Dots;
