/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { CustomFlowbiteTheme, Flowbite, Modal } from 'flowbite-react';
import { FormInput, GoogleAutocomplete, Button } from '@/components';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRequestFacilities } from '@/store/server/use-auth-query/use-auth-query';
import { useShallow } from 'zustand/react/shallow';
import { useAuthStore } from '@/store/client';
import { useAddAccount } from '@/store/server/use-auth-query/use-signup';

const customTheme: CustomFlowbiteTheme = {
  modal: {
    root: {
      base: 'fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full backdrop-blur-sm ',
      sizes: {
        lg: 'max-w-lg outline-none',
      },
    },
    content: {
      inner:
        'relative rounded-2xl bg-white shadow dark:bg-gray-700 flex flex-col max-h-[95vh] overflow-y-auto',
    },
  },
};

type FormData = {
  facilityName: string;
  facilityLocation: string;
  longitude: any;
  latitude: any;
  map_address: string;
};

const AddAccountModal = (props: any) => {
  const [currentFacilityId, setCurrentFacilityId] = useState<string>('');
  const [loadingLocation, setLoadingLocation] = useState(false);

  const currentFacility = useAuthStore(
    useShallow((state: any) => state.currentFacility),
  );

  useEffect(() => {
    setCurrentFacilityId(currentFacility?.id);
  }, [currentFacility]);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      facilityName: '',
      facilityLocation: '',
      longitude: '',
      latitude: '',
      map_address: '',
    },
  });

  const setFacilities = useAuthStore(state => state.setFacilities);

  const requestFacilities = useRequestFacilities({
    callbackOnSuccess: (data: any) => {
      setFacilities(data);
    },
  });

  const addAccount = useAddAccount({
    callbackOnSuccess: () => {
      reset({
        facilityName: '',
        facilityLocation: '',
        longitude: '',
        latitude: '',
        map_address: '',
      });
      props.setAddAccountModal(undefined);
      requestFacilities();
    },
  });

  const onSubmit: SubmitHandler<FormData> = (data, event) => {
    event?.preventDefault();
    if (!data.facilityLocation) {
      setError('facilityLocation', {
        type: 'required',
        message: 'Facility location is required.',
      });
      return;
    }
    const formDetail = {
      Address: data?.facilityLocation,
      Name: data?.facilityName,
      MapAddress: data?.facilityLocation,
      longitude: data?.longitude,
      latitude: data?.latitude,
    };

    addAccount(formDetail);
  };

  return (
    <>
      <Flowbite theme={{ theme: customTheme }}>
        <Modal
          position="top-right"
          size="lg"
          show={props.addAccountModal === 'placement'}
          onClose={() => {
            reset({
              facilityName: '',
              facilityLocation: '',
              longitude: '',
              latitude: '',
              map_address: '',
            });
            props.setAddAccountModal(undefined);
          }}
        >
          <Modal.Header>
            <div tw="flex items-center justify-center gap-6">
              <h1 tw="font-medium leading-8 text-[28px] text-[#010D26]">
                Add New Account
              </h1>
              <div tw="flex items-center justify-center rounded-3xl font-normal text-xs leading-3 text-[#219653] p-[10px] bg-[#EAFAF1]">
                NEW
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div tw="flex flex-col items-start justify-center gap-6">
                <div tw="flex w-full flex-col items-start border py-3 px-2 gap-[16px] rounded-[10px] border-[#F2F2F2] bg-[#FAFAFA]">
                  <FormInput<FormData>
                    id="facilityName"
                    name="facilityName"
                    label="Facility Name"
                    placeholder="Enter facility name"
                    leadingIcon="home"
                    tw="mb-4 w-full"
                    register={register}
                    autoComplete="off"
                    rules={{
                      required: 'Facility name is required',
                      validate: value => {
                        return !!value.trim();
                      },
                    }}
                    errors={errors}
                  />

                  <GoogleAutocomplete<FormData>
                    name="facilityLocation"
                    label="Facility Location"
                    placeholder="Choose location"
                    leadingIcon="location"
                    tw="mb-14"
                    setFormError={setError}
                    clearErrors={clearErrors}
                    getValues={getValues}
                    register={register}
                    rules={{
                      required: 'Facility location is required...',
                    }}
                    setValue={setValue}
                    errors={errors}
                    loading={loadingLocation}
                    setLoading={val => setLoadingLocation(val)}
                  />
                </div>
                <div tw="mt-8 flex w-full items-center justify-end gap-[36px]">
                  <Button
                    type="submit"
                    disabled={!isDirty || loadingLocation}
                    // tw="flex items-center justify-start font-medium border-[0.5px] border-[#E0E0E0] bg-[#00AEEF] rounded-[16px] px-[24px] py-[10px] text-[12px] text-[white]"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </Flowbite>
    </>
  );
};

export default AddAccountModal;
