/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { Modal } from 'flowbite-react';
import { useState } from 'react';
import tw, { css } from 'twin.macro';

const ShiftInfoModal = ({
  setOpenShiftModal,
  openShiftModal,
  setShiftEdit,
}: any) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const TabItem = ({
    activeTab,
    text,
    index,
  }: {
    activeTab: number;
    text: string;
    index: number;
  }) => (
    <button
      css={[
        tw`flex flex-auto h-full items-center justify-center text-center font-medium text-black leading-5 text-[14px] tracking-[-0.08px] p-[14px]`,
        activeTab === index &&
          tw`flex flex-auto h-full items-center justify-center border-[rgba(0, 0, 0, 0.04)] bg-white font-normal text-black leading-5 shadow p-[14px] text-[14px] tracking-[-0.08px] rounded-[7px] border-[0.5px]`,
      ]}
      onClick={() => setActiveTab(index)}
    >
      {text}
    </button>
  );

  const tabview = (index: number) => {
    switch (index) {
      case 0:
        return (
          <>
            <h1 tw="w-full text-center font-normal text-[#181818] text-[24px]">
              SHIFT TYPE
            </h1>
            <div tw="flex w-full flex-col items-start justify-start gap-[16px]">
              <p tw="text-justify font-thin text-base text-[#4F4F4F]">
                "Shift type" refers to a specific categorization of work hours
                or a scheduled period during which employees, including
                healthcare professionals, are assigned to perform their duties.
              </p>
              <p tw="text-justify font-thin text-base text-[#4F4F4F]">
                These shifts are typically organized to cover different parts of
                the day or night and are characterized by distinct timeframes,
                such as "morning shift," "afternoon shift," "night shift," or
                other similar designations.
              </p>
              <p tw="text-justify font-thin text-base text-[#4F4F4F]">
                The purpose of defining shift types is to establish a structured
                work schedule that ensures continuous and efficient coverage of
                tasks and responsibilities across various periods of the day,
                allowing for the seamless operation of the healthcare facility
                while accommodating the availability and preferences of staff
                members.
              </p>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <h1 tw="w-full text-center font-normal text-[#181818] text-[24px]">
              SHIFT DATE
            </h1>
            <div tw="flex w-full flex-col items-start justify-start gap-[16px]">
              <p tw="text-justify font-thin text-base text-[#4F4F4F]">
                "Shift date" refers to the specific calendar date on which a
                healthcare professional is scheduled to work during a shift at a
                healthcare facility.
              </p>
              <p tw="text-justify font-thin text-base text-[#4F4F4F]">
                It represents the day when the shift begins and ends, and it
                plays a crucial role in the organization of work schedules and
                staffing assignments.
              </p>
              <p tw="text-justify font-thin text-base text-[#4F4F4F]">
                Accurate tracking of shift dates is essential for ensuring that
                healthcare facilities have the appropriate staffing levels in
                place to meet patient care needs on any given day.
              </p>
              <p tw="text-center font-thin text-base text-[#4F4F4F]">
                Additionally, it helps professionals plan their workdays, making
                it easier to manage their commitments and availability.
              </p>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h1 tw="w-full text-center font-normal text-[#181818] text-[24px]">
              SHIFT TIME
            </h1>
            <div tw="flex w-full flex-col items-start justify-start gap-[16px]">
              <p tw="text-justify font-thin text-base text-[#4F4F4F]">
                "Shift time" refers to the designated period within a healthcare
                professional's work shift during which they are expected to be
                actively engaged in providing care or performing their assigned
                responsibilities at a healthcare facility.
              </p>
              <p tw="text-justify font-thin text-base text-[#4F4F4F]">
                Shift times are typically defined by specific start and end
                times, and they are crucial for organizing work schedules,
                coordinating staff assignments, and ensuring the continuous
                delivery of patient care services.
              </p>
              <p tw="text-justify font-thin text-base text-[#4F4F4F]">
                Shift times can vary based on the nature of the shift, facility
                policies, and the needs of the patients. For example, in a
                hospital setting, a "morning shift" might typically run from
                7:00 AM to 3:00 PM, while an "evening shift" could extend from
                3:00 PM to 11:00 PM.
              </p>
              <p tw="text-justify font-thin text-base text-[#4F4F4F]">
                Accurate tracking and adherence to shift times are essential to
                maintain operational efficiency, facilitate shift handovers, and
                provide consistent and uninterrupted care to patients
              </p>
            </div>
          </>
        );
    }
  };

  return (
    <>
      <Modal
        size="lg"
        dismissible
        popup
        show={openShiftModal === 'pop-up'}
        onClose={() => setOpenShiftModal(undefined)}
      >
        <Modal.Body>
          <div tw="flex w-full flex-col flex-wrap items-start pt-8 text-center gap-[20px]">
            <div tw="flex w-full flex-shrink-0 items-start bg-[rgba(118, 118, 128, 0.12)] h-[47px] p-[2px] rounded-[8px]">
              <ul
                tw="flex h-full w-full items-stretch justify-between"
                id="myTab"
                data-tabs-toggle="#myTabContent"
                role="tablist"
              >
                <li role="presentation">
                  <TabItem index={0} activeTab={activeTab} text="Shift Type" />
                </li>
                <li role="presentation">
                  <TabItem index={1} activeTab={activeTab} text="Shift Date" />
                </li>
                <li role="presentation">
                  <TabItem index={2} activeTab={activeTab} text="Shift Time" />
                </li>
              </ul>
            </div>
            {activeTab !== -1 && <div tw="w-full">{tabview(activeTab)}</div>}
            <button
              onClick={() => {
                setOpenShiftModal(undefined);
                setShiftEdit(true);
              }}
              tw="flex w-full items-center justify-center font-normal text-white p-[10px] rounded-[52px] bg-[#00AEEF] text-[20px]"
            >
              Yes, I understand now
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShiftInfoModal;
