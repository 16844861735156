/** @jsxImportSource @emotion/react */
import {
  HTMLInputTypeAttribute,
  useState,
  FC,
  forwardRef,
  ChangeEvent,
} from 'react';
import tw from 'twin.macro';
import { Spinner } from 'flowbite-react';

export type DateInputProps = {
  id?: string;
  name?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute | undefined;
  required?: boolean;
  loading?: boolean;
  value?: string;
  hasError?: boolean;
  disabled?: boolean;
  pattern?: string;
  onChange?: (event: any) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
};

const DateInput: FC<DateInputProps> = forwardRef<
  HTMLInputElement,
  DateInputProps
>(
  (
    {
      id,
      name,
      placeholder,
      type,
      required,
      hasError,
      value,
      disabled,
      loading,
      pattern,
      onChange,
      onFocus,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const [focus, setFocus] = useState(false);

    return (
      <>
        <input
          ref={ref}
          type="text"
          id={id}
          name={name}
          css={[
            tw`rounded-md border bg-white font-medium text-sm w-[130px] text-[#141414] border-[#F2F2F2] placeholder:text-[#141414] focus:(border-none outline-none ring-[#00AEEF])`,
            !value && !focus && tw`bg-white border-[#F2F2F2]`,
            hasError &&
              tw`animate-shake self-end border-red-600 bg-red-50 outline-red-600 focus:(border-red-600 ring-red-500)`,
          ]}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          pattern={pattern}
          onChange={onChange}
          onFocus={e => {
            e.target.type = 'date';
          }}
          onBlur={e => {
            e.target.type = 'text';
          }}
          {...props}
        />

        {loading && (
          <div tw="absolute inset-y-0 right-0 z-10 flex cursor-pointer items-center pr-4">
            <Spinner size="md" color="info" aria-label="Loading spinner" />
          </div>
        )}
      </>
    );
  },
);

export default DateInput;
