/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import NProgress from 'nprogress';
import 'twin.macro';

const Loading = () => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return <div tw="min-h-full bg-red-200"></div>;
};

export default Loading;
