import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../constants/route-path';
import { storage } from '@/utils';

export const useAuthGuard = () => {
  const navigate = useNavigate();
  const token = storage.getToken();

  useEffect(() => {
    if (!token) {
      navigate(RoutePath.login);
    }
  }, [token]);
};
