/** @jsxImportSource @emotion/react */
import {
  ChangeEventHandler,
  HTMLInputTypeAttribute,
  useState,
  FC,
  forwardRef,
  useEffect,
} from 'react';
import tw from 'twin.macro';
import Icons from '../icons';
import eyeOpen from '@/assets/svg/eye-open.svg';
import eyeClosed from '@/assets/svg/eye-closed.svg';
import { Spinner } from 'flowbite-react';

type LeadingIcon =
  | 'envelope'
  | 'home'
  | 'location'
  | 'lock'
  | 'map-pin-line'
  | 'phone'
  | 'user'
  | 'shift'
  | 'shiftTime';

export type InputProps = {
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute | undefined;
  leadingIcon?: LeadingIcon;
  required?: boolean;
  loading?: boolean;
  className?: string;
  minimum?: string;
  // step?: string;
  value?: string;
  hasError?: boolean;
  disabled?: boolean;
  autoComplete?: string;
  pattern?: string;
  style?: any;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      label,
      placeholder,
      type,
      leadingIcon,
      required,
      className,
      minimum,
      hasError,
      value,
      disabled,
      loading,
      autoComplete,
      pattern,
      onChange,
      style,
      ...props
    },
    ref,
  ) => {
    const [focus, setFocus] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const getLeadingIcon = () => {
      const color = hasError
        ? '#dc2626'
        : value || focus
        ? '#00AEEF'
        : '#E0E0E0';
      switch (leadingIcon) {
        case 'envelope':
          return <Icons.Envelope color={color} />;
        case 'home':
          return <Icons.Home color={color} />;
        case 'location':
          return <Icons.MapPinLine color={color} />;
        case 'lock':
          return <Icons.Lock color={color} />;
        case 'map-pin-line':
          return <Icons.MapPinLine color={color} />;
        case 'phone':
          return <Icons.Phone color={color} />;
        case 'user':
          return <Icons.User color={color} />;
        case 'shift':
          return <Icons.Shift color={color} />;
        case 'shiftTime':
          return <Icons.ShiftTime color={color} />;
        default:
          return <></>;
      }
    };

    return (
      <div tw="flex w-full flex-col" className={className}>
        <label
          htmlFor={id}
          tw="mb-1 font-medium text-base text-primary-900"
          css={[hasError && tw`text-red-700`]}
        >
          {label}
          {required && <span tw="font-medium text-red-500 text-lg">*</span>}
        </label>
        <div tw="relative">
          <div tw="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-5">
            {getLeadingIcon()}
          </div>
          <input
            ref={ref}
            type={showPassword ? 'text' : type}
            id={id}
            name={name}
            tw="w-full border border-primary border-opacity-10 p-5 pl-12 text-primary-900 text-sm placeholder-silver outline-primary-500 bg-[#F1F6FE] rounded-[10px] focus:(border-primary ring-primary-500)"
            css={[
              !value && !focus && tw`bg-transparent border-[#E6E6E6]`,
              type === 'password' && tw`pr-12`,
              !leadingIcon && tw`pl-5`,
              hasError &&
                tw`animate-shake border-red-600 bg-red-50 outline-red-600 focus:(border-red-600 ring-red-500)`,
            ]}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete={autoComplete}
            style={style}
            value={value}
            pattern={pattern}
            onChange={onChange}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            min={minimum}
            {...props}
          />

          {type === 'password' && (
            <button
              type="button"
              tw="absolute inset-y-0 right-0 z-10 flex cursor-pointer items-center pr-5"
              css={[type === 'password' && tw``]}
              onClick={() => setShowPassword(!showPassword)}
              tabIndex={-1}
            >
              <img
                src={!showPassword ? eyeClosed : eyeOpen}
                alt={!showPassword ? 'Closed eye icon' : 'Open eye icon'}
                tw="cursor-pointer"
              />
            </button>
          )}
          {loading && (
            <div tw="absolute inset-y-0 right-0 z-10 flex cursor-pointer items-center pr-4">
              <Spinner size="md" color="info" aria-label="Loading spinner" />
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default Input;
