import { axios } from '@/utils';
import {
  AddAccountRequest,
  AddAccountResponse,
  CreateShiftResponse,
  DashboardData,
  DeleteShiftResponse,
  EditShiftResponse,
  LoginResponse,
  PostAccountResponse,
  ShiftList,
  SignupRequest,
  SignupResponse,
  TimesheetList,
  TimesheetQRData,
  TokenResponse,
  UpdateFacilityResponse,
  VerifyOTPRequest,
  UploadPhotoResponse,
  ChangePasswordResponse,
  GetUploadResponse,
  DeleteUploadResponse,
  DeleteProfileUploadResponse,
  BookingList,
  BookingListDetail,
  ValidateBookingResponse,
  ApproveTimesheetResponse,
  RateProfessionalResponse,
  RatingTags, ShiftRateResponse,
} from './types';
import { ErrorManager } from '@/utils/helper';
import { Facilities, Licenses, ShiftCategory } from '@/store/client/types';
import {useAuthStore} from "@/store/client";
import {useShallow} from "zustand/esm/react/shallow";

const login = async (
  email: string,
  password: string,
): Promise<LoginResponse> => {
  try {
    const request = {
      email,
      password,
    };
    const response = await axios.post('/auth/login', request);
    return response.data?.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const signup = async (request: SignupRequest): Promise<SignupResponse> => {
  try {
    const response = await axios.post('/fac/create-facility', request);
    return response.data?.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const addAccount = async (
  request: AddAccountRequest,
): Promise<AddAccountResponse> => {
  try {
    const response = await axios.post('/fac/add-new-facility', request);
    return response.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const forgotPassword = async (email: string): Promise<string> => {
  try {
    const request = { email };
    const response = await axios.post('/auth/forgot-password', request);
    return response.data?.data ?? '';
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const requestOTP = async (): Promise<string> => {
  try {
    const response = await axios.get('/auth/send-otp');
    return response.data?.data ?? '';
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const verifyEmailAndOTP = async (
  request: VerifyOTPRequest,
): Promise<TokenResponse> => {
  try {
    const response = await axios.post('/auth/verify-otp', request);
    return response.data?.data ?? '';
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const verifyOTP = async (otp: string): Promise<TokenResponse> => {
  try {
    const request = { otp };
    const response = await axios.post('/auth/verify-email', request);
    return response.data?.data ?? '';
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const resetPassword = async (newPassword: string): Promise<void> => {
  try {
    const request = { newPassword };
    await axios.post('/auth/reset-password', request);
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const requestFacilities = async (): Promise<Facilities> => {
  try {
    const response = await axios.get('/fac/account/info');
    // console.log(response.data.data);
    return response.data?.data ?? {};
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const requestLicenses = async (): Promise<Licenses> => {
  try {
    const response = await axios.get('/data/license');
    return response.data?.data ?? [];
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const requestShiftCategory = async (): Promise<ShiftCategory> => {
  try {
    const response = await axios.get('/data/shift-category');
    return response.data?.data ?? [];
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const requestShiftList = async (
  currentFacilityId: string,
  status: string,
  startDate: string,
  endDate: string,
  selectShiftDuraton: string,
  selectShiftType: string,
): Promise<ShiftList> => {
  try {
    const response = await axios.get(
      `/fac/${currentFacilityId}/shift?status=${status}&startDate=${startDate}&endDate=${endDate}&duration=${selectShiftDuraton}&shift=${selectShiftType}`,
    );
    return response.data?.data ?? [];
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const createShift = async (
  licenseId: string,
  task: string,
  shiftCategoryId: string,
  startDate: string,
  endDate: string,
  startTime: string,
  endTime: string,
  currentFacilityId: string,
): Promise<CreateShiftResponse> => {
  try {
    const request = {
      licenseId,
      task,
      shiftCategoryId,
      startDate,
      endDate,
      startTime,
      endTime,
    };
    const response = await axios.post(
      `/fac/${currentFacilityId}/shift`,
      request,
    );
    return response.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const editShift = async (
  task: string,
  shiftId: string,
  currentFacilityId: string,
): Promise<EditShiftResponse> => {
  try {
    const request = {
      task,
    };
    const response = await axios.patch(
      `/fac/${currentFacilityId}/shift/${shiftId}`,
      request,
    );
    return response.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const deleteShift = async (
  shiftId: string,
  currentFacilityId: string,
): Promise<DeleteShiftResponse> => {
  try {
    const response = await axios.delete(
      `/fac/${currentFacilityId}/shift/${shiftId}/delete`,
    );
    return response.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const requestDashboard = async (
  currentFacilityId: string,
): Promise<DashboardData> => {
  try {
    const response = await axios.get(`/fac/${currentFacilityId}/metrics`);
    return response.data?.data ?? {};
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const requestTimesheetQR = async (
  currentFacilityId: string,
): Promise<TimesheetQRData> => {
  try {
    const response = await axios.get(`/fac/${currentFacilityId}/qr-data`);
    return response.data?.data ?? {};
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const requestTimesheetList = async (
  currentFacilityId: string,
): Promise<TimesheetList> => {
  try {
    const response = await axios.get(`/fac/${currentFacilityId}/timesheet`);
    return response.data?.data ?? [];
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const postAccountInfo = async (
  firstName: string,
  lastName: string,
  phoneNumber: string,
): Promise<PostAccountResponse> => {
  try {
    const request = {
      firstName,
      lastName,
      phoneNumber,
    };
    const response = await axios.post(`/fac/account/info`, request);
    return response.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const updateFacility = async (
  name: string,
  address: string,
  longitude: any,
  latitude: any,
  defaultFac: boolean,
  currentFacilityId: string,
): Promise<UpdateFacilityResponse> => {
  try {
    const request = {
      name,
      address,
      longitude,
      latitude,
      defaultFac,
    };
    const response = await axios.patch(`/fac/${currentFacilityId}`, request);
    return response.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const uploadPhoto = async (
  file: any,
  description: string,
  tag: string,
  currentFacilityId: string,
): Promise<UploadPhotoResponse> => {
  try {
    const request = new FormData();
    request.append('file', file);
    request.append('description', description);
    request.append('tag', tag);
    console.log(description, tag);
    const response = await axios.post(
      `/fac/${currentFacilityId}/account/upload`,
      request,
    );
    return response.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const getUpload = async (
  currentFacilityId: string,
): Promise<GetUploadResponse> => {
  try {
    const response = await axios.get(
      `/fac/${currentFacilityId}/account/upload`,
    );
    return response.data?.data ?? [];
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const deleteUpload = async (
  tag: string,
  currentFacilityId: string,
): Promise<DeleteUploadResponse> => {
  try {
    const response = await axios.delete(
      `/fac/${currentFacilityId}/account/upload/${tag}`,
    );
    return response.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const changePassword = async (
  currentPassword: string,
  newPassword: string,
): Promise<ChangePasswordResponse> => {
  try {
    const request = {
      currentPassword,
      newPassword,
    };
    const response = await axios.post(`/auth/change-pwd`, request);
    return response.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const uploadProfilePhoto = async (file: any) => {
  try {
    const request = new FormData();
    request.append('file', file);

    const response = await axios.post(`/fac/account/photo`, request);
    return response.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const deleteProfileUpload = async (): Promise<DeleteProfileUploadResponse> => {
  try {
    const response = await axios.delete(`/fac/account/photo`);
    return response.data;
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const requestBookingList = async (
  currentFacilityId: string,
  // status: string,
  // startDate: string,
  // endDate: string,
  // selectShiftDuraton: string,
  // selectShiftType: string,
): Promise<BookingList> => {
  try {
    const response = await axios.get(`/fac/${currentFacilityId}/booking`);
    return response.data?.data[0] ?? [];
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const requestBookingListDetail = async (
  currentFacilityId: string,
  openShiftId: string,
  // status: string,
  // startDate: string,
  // endDate: string,
  // selectShiftDuraton: string,
  // selectShiftType: string,
): Promise<BookingListDetail> => {
  try {
    const response = await axios.get(
      `/fac/${currentFacilityId}/booking/${openShiftId}`,
    );
    return response.data?.data ?? {};
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const validateBooking = async (
  currentFacilityId: string,
  openShiftId: string,
  closeShift: boolean,
  approve: any,
  decline: any,
  // status: string,
  // startDate: string,
  // endDate: string,
  // selectShiftDuraton: string,
  // selectShiftType: string,
): Promise<ValidateBookingResponse> => {
  try {
    const request = {
      closeShift,
      approve,
      decline,
    };
    const response = await axios.post(
      `/fac/${currentFacilityId}/booking/${openShiftId}`,
      request,
    );
    return response.data?.data ?? {};
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const approveTimesheet = async (
  currentFacilityId: string,
  openShiftAssignId: string,
  userId: string,
  approved: boolean,
): Promise<ApproveTimesheetResponse> => {
  try {
    const request = {
      openShiftAssignId,
      userId,
      approved,
    };
    const response = await axios.post(
      `/fac/${currentFacilityId}/timesheet`,
      request,
    );
    return response.data?.data ?? {};
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const rateProfessional = async (
  id: string,
  rating: number,
  review: string,
  tags: string,
): Promise<RateProfessionalResponse> => {
  try {
    const request = {
      id,
      rating,
      review,
      tags,
    };
    const response = await axios.post(`/rating`, request);
    return response.data?.data ?? {};
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const getRatingTags = async (): Promise<RatingTags[]> => {
  try {
    const response = await axios.get(`/data/rationale`);
    return response.data?.data ?? [];
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};


const getShiftRate = async (facilityId:string): Promise<ShiftRateResponse> => {
  try {
    const response = await axios.get(`/fac/${facilityId}/rate`);
    return response.data?.data ?? [];
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};
const createShiftRate = async (
    licenseId: number,
    morning: number,
    afternoon: number,
    night: number,
    rateType: number,
    currentFacilityId: string,
): Promise<ShiftRateResponse> => {
  try {
    const request = [{
      licenseId,
      morning,
      afternoon,
      night,
      rateType
    }];
    const response = await axios.post(
        `/fac/${currentFacilityId}/rate`,
        request,
    );

    return response.data?.data ?? [];
  } catch (error) {
    throw ErrorManager.getMessage(error);
  }
};

const AuthService = {
  login,
  forgotPassword,
  requestOTP,
  resetPassword,
  signup,
  addAccount,
  verifyOTP,
  verifyEmailAndOTP,
  requestFacilities,
  requestLicenses,
  requestShiftCategory,
  createShift,
  requestShiftList,
  editShift,
  deleteShift,
  requestDashboard,
  requestTimesheetQR,
  requestTimesheetList,
  postAccountInfo,
  updateFacility,
  uploadPhoto,
  getUpload,
  deleteUpload,
  changePassword,
  uploadProfilePhoto,
  deleteProfileUpload,
  requestBookingList,
  requestBookingListDetail,
  validateBooking,
  approveTimesheet,
  rateProfessional,
  getRatingTags,
  createShiftRate,
  getShiftRate
};

export default AuthService;
