import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type AppState = {
  loading: boolean;
  showLoading: () => void;
  hideLoading: () => void;

  isAuthenticated: boolean;
  toastMessage: string;
  toastType: 'success' | 'error';
  authenticate: () => void;
  unAuthenticate: () => void;
  showSuccessToast: (message: string) => void;
  showErrorToast: (message?: string) => void;
  resetToast: () => void;

  pageTitle: string;
  setPageTitle: (message: string) => void;
};

const useAppStore = create<AppState>()(
  devtools(
    persist(
      set => ({
        isAuthenticated: false,
        authenticate: () => set(() => ({ isAuthenticated: true })),
        unAuthenticate: () => set(() => ({ isAuthenticated: false })),

        loading: false,
        showLoading: () => set(() => ({ loading: true })),
        hideLoading: () => set(() => ({ loading: false })),

        toastMessage: '',
        toastType: 'success',
        showSuccessToast: (message: string) =>
          set(() => ({ toastMessage: message, toastType: 'success' })),
        showErrorToast: (message = 'An error occurred. Please try again.') =>
          set(() => ({ toastMessage: message, toastType: 'error' })),
        resetToast: () => set(() => ({ toastMessage: '' })),

        pageTitle: '',
        setPageTitle: (title: string) => set(() => ({ pageTitle: title })),
      }),
      {
        name: 'app-store',
      },
    ),
    {
      enabled: true,
    },
  ),
);

export default useAppStore;
