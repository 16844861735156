/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import get from 'lodash.get';
import {
  RegisterOptions,
  DeepMap,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
  UseFormGetValues,
  UseFormSetError,
  UseFormClearErrors,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Icons from '../icons';
import Input, { InputProps } from '../input';
import { useEffect, useRef, useState } from 'react';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

export type GoogleAutocompleteProps<TFormValues> = {
  name: string;
  loading?: boolean;
  setLoading?: (val: boolean) => void;
  rules?: RegisterOptions;
  setValue?: UseFormSetValue<any>;
  getValues?: UseFormGetValues<any>;
  setFormError?: UseFormSetError<any>;
  clearErrors?: UseFormClearErrors<any>;
  register?: UseFormRegister<any>;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
  onSelect?: (location: string) => void;
} & Omit<InputProps, 'name'>;

const GoogleAutocomplete = <TFormValues extends Record<string, unknown>>({
  name,
  loading,
  setLoading,
  setValue,
  getValues,
  setFormError,
  clearErrors,
  register,
  rules,
  errors,
  className,
  onSelect,
  ...props
}: GoogleAutocompleteProps<TFormValues>) => {
  const [suggestionFocused, setSuggestionFocused] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState<string>('');

  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`,
        document.querySelector('head'),
        'google-maps',
      );
    }
    loaded.current = true;
  }

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue: setPlacesValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'initMap',
    requestOptions: {
      componentRestrictions: { country: 'ng' },
    },
    debounce: 300,
  });

  useEffect(() => {
    if (register) {
      if (getValues) {
        setPlacesValue(getValues!('facilityLocation'));
      }
    }
  }, [name]);

  useEffect(() => {
    if (clearErrors && errors && errors!['facilityLocation']) {
      console.log('erros::: ', errors['facilityLocation']);
      clearErrors(name);
    }
  }, [value]);

  const registerRef = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setPlacesValue(e.target.value);
  };

  const handleSelect =
    ({ description }: { description: string }) =>
    () => {
      if (setLoading) setLoading(true);
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter as "false"
      setPlacesValue(description, false);
      if (setValue) {
        setValue(name, description);
      }
      // onSelect(description);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }: any) => {
          if (setValue) {
            setValue(name, description);
            setValue('longitude', lng);
            setValue('latitude', lat);
          }
          if (setLoading) setLoading(false);
        })
        .catch(error => {
          if (setValue) {
            setValue(name, '');
            setValue('longitude', null);
            setValue('latitude', null);
            setValue('map_address', '');
          }
          if (setLoading) setLoading(false);
          console.log('? Error: ', error);
        });

      //get map_address
      getGeocode({ address: description }).then(results => {
        console.log(results[0].formatted_address);
        if (setValue) {
          setValue('mapAddress', results[0]?.formatted_address);
        }
      });
    };

  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div ref={registerRef} tw="relative w-full" className={className}>
      <Input
        name={name}
        aria-invalid={hasError}
        hasError={hasError}
        disabled={!ready}
        required={!!rules?.required}
        loading={loading}
        value={value}
        onChange={handleInput}
        autoComplete="off"
        {...props}
        // {...(register && register(name, rules))}
      />

      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && (
        <ul tw="absolute left-0 z-50 flex w-full flex-col rounded-lg border bg-white shadow-sm border-[#E6E6E6] top-[88px]">
          {data.map((suggestion, index) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <li
                key={place_id}
                onClick={handleSelect(suggestion)}
                tw="flex cursor-pointer gap-2 border-custom-gray-600 border-t py-3 px-6 hover:(bg-custom-gray-600 bg-opacity-75)"
                css={[index === 0 && tw`border-t-0`]}
                onMouseEnter={() => setSuggestionFocused(true)}
                onMouseLeave={() => setSuggestionFocused(false)}
              >
                <Icons.MapPin color="#010D26" tw="mt-1" />
                <div tw="flex flex-col">
                  <h4 tw="font-semibold text-sm text-primary-900">
                    {main_text}
                  </h4>
                  <p tw="text-xs text-primary-900">{secondary_text ?? '...'}</p>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name as any}
          render={({ message }) => (
            <p className="mt-1 ml-1 text-left text-sm text-red-600">
              {message}
            </p>
          )}
        />
      )}
    </div>
  );
};

export default GoogleAutocomplete;
