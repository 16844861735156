/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { FC, useEffect, useState } from 'react';
import { Icons } from '@/components';
import user from '@/assets/images/user.png';
import PropTypes from 'prop-types';
import ShiftModal from '@/views/shift-management/modals/shift-modal';
import { Badge, Dropdown } from 'flowbite-react';
import { useAuthStore } from '@/store/client';
import { useShallow } from 'zustand/react/shallow';
import {useFacilityShiftRate, useRequestFacilities} from '@/store/server/use-auth-query/use-auth-query';
import AddAccountModal from '@/views/dashboard/modal/add-account-modal';
import { useNavigate } from 'react-router-dom';

type HeaderProps = {
  label: string;
  openModal?: string;
  setOpenModal?: Function;
  addAccountModal?: string;
  setAddAccountModal?: Function;
};

const Header: FC<HeaderProps> = ({ label }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<string | undefined>();
  const [addAccountModal, setAddAccountModal] = useState<string | undefined>();
  const props = {
    openModal,
    setOpenModal,
    addAccountModal,
    setAddAccountModal,
  };

  const facilities = useAuthStore(useShallow((state: any) => state.facilities));
  const currentFacility = useAuthStore(useShallow((state: any) => state.currentFacility),);
  const setCurrentFacility = useAuthStore(state => state.setCurrentFacility);
  const setFacilities = useAuthStore(state => state.setFacilities);

  const requestFacilities = useRequestFacilities({
    callbackOnSuccess: (data: any) => {
      // console.log(data);
      setFacilities(data);
      data?.facilities?.map((value: any) => {
        if (value?.id === currentFacility?.id) {
          setCurrentFacility(value);
        } else if (value?.defaultFac === true) {
          setCurrentFacility(value);
        }
      });
    },
  });
  useEffect(() => {
    requestFacilities();
  }, []);

  const getFacilityInitials = (value: string) => {
    if (value && value.includes(' ')) {
      const firstValue = value?.split(' ')[0].slice(0, 1).toUpperCase();
      const secondValue = value?.split(' ')[1].slice(0, 1).toUpperCase();
      return `${firstValue}${secondValue}`;
    } else {
      const onlyValue = value?.slice(0, 1).toUpperCase();
      return `${onlyValue}`;
    }
  };


  const [currentFacilityId, setCurrentFacilityId] = useState<string>('');
  const [facilityShiftRates, setFacilityShiftRates]:any = useState<{
    licenseId: number,
    morning: number,
    afternoon: number,
    night: number,
    rateType: number
  }[]>([]);
  const getFacilityShiftRates = useFacilityShiftRate({onSuccess: (d:any) => {
      setFacilityShiftRates(d)}
  });

  useEffect(() => {
    setCurrentFacilityId(currentFacility?.id);
    if (currentFacilityId){
      getFacilityShiftRates({currentFacilityId});
    }

  }, [currentFacilityId]);

  return (
    <>
      <div tw="mb-8 flex w-full items-center justify-between">
        <h1 tw="font-semibold text-3xl text-[#040417]">{label}</h1>
        <div tw="relative flex items-center gap-4 border-none">
          <span tw="absolute left-4">
            <Icons.Search />
          </span>
          <input
            id="search"
            type="search"
            placeholder="search..."
            tw="h-8 rounded-3xl border border-white bg-white font-medium text-base shadow-sm text-[#010D26] p-[24px] pl-[42px] min-w-[400px] max-w-[715px] focus:(border-white ring-0)"
          />
          <div tw="flex items-center justify-center rounded-2xl bg-white p-[14px]">
            <div tw="relative cursor-pointer">
              <Icons.Notification />
              <span tw="absolute top-0 right-0 flex h-3 w-3 items-center justify-center rounded-full p-2 font-semibold text-white text-[8px] bg-[#EB4545]">
                20
              </span>
            </div>
          </div>

          <button
            tw="flex items-center justify-center gap-3 rounded-2xl border bg-primary p-6 font-medium text-white text-base h-[32px]"
            onClick={() => {
              props.setOpenModal('placement');
            }}
          >
            <Icons.Plus />
            Book New Shift
          </button>

          <div tw="relative rounded-full w-[60px] h-[60px]">
            {!user ? (
              <img src={user} alt="user" tw="h-full w-full rounded-full" />
            ) : (
              <div tw="flex h-full w-full items-center justify-center rounded-full text-center font-semibold text-white leading-normal bg-[#00AEEF] text-[20px]">
                {getFacilityInitials(currentFacility?.name)}
              </div>
            )}
            <Dropdown
              label=""
              placement="bottom-end"
              size="sm"
              renderTrigger={() => (
                <p tw="absolute top-0 left-0 z-50 h-full w-full cursor-pointer"></p>
              )}
              tw="z-50 border bg-white py-4 shadow-sm-light w-[300px] rounded-[6px] border-[#E6E6E6]"
            >
              <div tw="flex w-full flex-col items-center gap-6">
                <div tw="flex w-full items-center justify-between px-4">
                  <p tw="font-semibold text-base text-[#141414]">Account</p>
                  {/* <button tw="flex items-center justify-center rounded-full border px-2 font-medium text-base border-[#F2F2F2] bg-[#FAFAFA] focus:outline-none">
                    x
                  </button> */}
                </div>
                <div tw="relative flex w-full flex-col items-center justify-center px-4">
                  <div tw="rounded-full w-[60px] h-[60px]">
                    {!user ? (
                      <img
                        src={user}
                        alt="user"
                        tw="h-full w-full rounded-full"
                      />
                    ) : (
                      <>
                        <div tw="flex h-full w-full items-center justify-center rounded-full text-center font-semibold text-white leading-normal bg-[#00AEEF] text-[20px]">
                          {getFacilityInitials(currentFacility?.name)}
                        </div>
                      </>
                    )}
                  </div>
                  <Badge tw="absolute text-white text-inherit bg-[#27AE60] bottom-[-12px] h-[22px] rounded-[100px] text-[10px]">
                    Active Account
                  </Badge>
                </div>
                <div tw="flex w-full flex-col items-center px-2 gap-[2px]">
                  <p tw="text-center font-semibold text-base text-[#141414]">
                    {currentFacility?.name}
                  </p>
                  <p tw="text-center font-normal text-sm text-[#4F4F4F]">
                    {currentFacility?.address}
                  </p>
                </div>
                <div tw="flex w-full flex-col gap-0">
                  {facilities &&
                    facilities?.facilities?.map((facility: any, index: any) => {
                      if (facility.id !== currentFacility.id) {
                        return (
                          <div
                            key={index}
                            tw="flex w-full items-center justify-between border-t py-3 px-2 gap-[2px] border-t-[#E0E0E0] bg-[#F2F2F2]"
                          >
                            <div tw="flex items-center gap-2">
                              <div tw="flex-shrink-0 rounded-full w-[30px] h-[30px]">
                                {!user ? (
                                  <img
                                    src={user}
                                    alt="user"
                                    tw="h-full w-full rounded-full"
                                  />
                                ) : (
                                  <>
                                    <div tw="flex h-full w-full items-center justify-center rounded-full text-center font-semibold text-white leading-normal bg-[#00AEEF] text-[10px]">
                                      {getFacilityInitials(facility?.name)}
                                    </div>
                                  </>
                                )}
                              </div>
                              <div tw="flex flex-col items-start">
                                <p tw="text-left font-medium text-sm text-[#4F4F4F]">
                                  {facility?.name}
                                </p>
                                <p tw="text-left font-medium text-[10px] text-[#4F4F4F]">
                                  {facility?.address}
                                </p>
                              </div>
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                setCurrentFacility(facility);
                                navigate(0);
                              }}
                              tw="flex items-center justify-center border bg-white px-4 text-center font-normal text-xs py-[6px] rounded-[100px] border-[#E0E0E0] text-[#010D26]"
                            >
                              Switch
                            </button>
                          </div>
                        );
                      }
                    })}
                </div>
                <button
                  onClick={() => props.setAddAccountModal('placement')}
                  tw="flex items-center justify-center rounded-2xl bg-white p-4 text-center font-medium text-base text-[#00AEEF]"
                >
                  <span tw="mr-1">
                    <Icons.Plus color="#00AEEF" />
                  </span>
                  Add Another Account
                </button>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <ShiftModal openModal={openModal} setOpenModal={setOpenModal} facilityShiftRates={facilityShiftRates} />
      <AddAccountModal
        addAccountModal={addAccountModal}
        setAddAccountModal={setAddAccountModal}
      />
    </>
  );
};

Header.propTypes = {
  openModal: PropTypes.string,
  setOpenModal: PropTypes.func,
  addAccountModal: PropTypes.string,
  setAddAccountModal: PropTypes.func,
};

export default Header;
