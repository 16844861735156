/** @jsxImportSource @emotion/react */
import { ChangeEventHandler, useState, FC, forwardRef } from 'react';
import tw from 'twin.macro';
import { Spinner } from 'flowbite-react';

export type SelectProps = {
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  loading?: boolean;
  value?: string;
  hasError?: boolean;
  disabled?: boolean;
  children?: any;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
};

const Select: FC<SelectProps> = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      id,
      name,
      label,
      required,
      hasError,
      value,
      disabled,
      loading,
      onChange,
      children,
      ...props
    },
    ref,
  ) => {
    const [focus, setFocus] = useState(false);

    return (
        <div>
            {label ?
                <label
                    htmlFor={id}
                    tw="mb-1 font-medium text-base text-primary-900"
                    css={[hasError && tw`text-red-700`]}
                >
                    {label}
                    {required && <span tw="font-medium text-red-500 text-lg">*</span>}
                </label> : ""
            }

            <div tw="relative w-full">
            <select
                ref={ref}
                id={id}
                name={name}
                tw="flex-1 w-full font-medium rounded-[6px] border-[#F2F2F2] text-[12px] bg-[#fff] text-[#828282] focus:(border-0 ring-[#00AEEF])"
                css={[
                    !value && !focus && tw`bg-white border-[#F2F2F2]`,
                    hasError &&
                    tw`animate-shake border-red-600 bg-red-50 outline-red-600 focus:(border-red-600 ring-red-500)`,
                    name !== 'requestType' && tw`self-stretch w-[130px]`,
                ]}
                disabled={disabled}
                value={value}
                onChange={onChange}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                {...props}
            >
                {children}
            </select>

            {loading && (
                <div tw="absolute inset-y-0 right-0 z-10 flex cursor-pointer items-center pr-4">
                    <Spinner size="md" color="info" aria-label="Loading spinner" />
                </div>
            )}
        </div>
        </div>
    );
  },
);

export default Select;
