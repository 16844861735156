/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { Modal } from 'flowbite-react';
import { Icons } from '@/components';
import { useNavigate, useLocation } from 'react-router-dom';

const SuccessModal = (props: any) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <Modal
        size="sm"
        dismissible
        popup
        show={props.openSuccessModal === 'pop-up'}
        onClose={() => {
          props.setOpenModal(undefined);
          props.setOpenSuccessModal(undefined);
          navigate(0);
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div tw="flex w-full flex-col items-center text-center gap-[35px]">
            <div>
              <Icons.SuccessIcon />
            </div>
            <h3 tw="w-full text-center font-semibold text-[#040417] text-[32px] leading-[42px]">
              {props.header}
            </h3>
            <p tw="font-medium text-base text-[#4D4D4D] leading-[26px]">
              {props.body}
            </p>
            <button
              onClick={() => {
                if (pathname === '/shift-management') {
                  props.setOpenModal(undefined);
                  props.setOpenSuccessModal(undefined);
                  navigate(0);
                } else {
                  navigate('/shift-management');
                }
              }}
              tw="flex w-full items-center justify-center font-medium text-white p-[10px] rounded-[14px] bg-[#00AEEF] text-[20px]"
            >
              {props.button}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessModal;
