/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { CustomFlowbiteTheme, Flowbite, Modal } from 'flowbite-react';
import { FormSelect, Icons } from '@/components';
import { useEffect, useState } from 'react';
import TaskInfoModal from './taskinfo-modal';
import tw, { css } from 'twin.macro';
import ShiftInfoModal from './shiftinfo-modal';
import SuccessModal from './success-modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import ShiftFormTextArea from '@/components/form/shift-formtextarea/shift-formtextarea';
import FormDateInput from '@/components/form/form-date-input/form-date-input';
import FormTimeInput from '@/components/form/form-time-input/form-time-input';
import {
  useCreateShift,
  useRequestLicenses,
  useRequestShiftCategory,
} from '@/store/server/use-auth-query/use-auth-query';
import { useShallow } from 'zustand/react/shallow';
import { useAuthStore } from '@/store/client';
import rateTab from "@/views/account/tabs/shiftRates/rate-tab";
import NotificationModal from "@/views/shift-management/modals/notification-modal";

type CreateShift = {
  requestType: string;
  task: string;
  shiftType: string;
  shiftStartDate: string;
  shiftEndDate: string;
  shiftStartTime: string;
  shiftEndTime: string;
};

const customTheme: CustomFlowbiteTheme = {
  modal: {
    root: {
      base: 'fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full backdrop-blur-sm ',
      sizes: {
        lg: 'max-w-lg outline-none',
      },
    },
    content: {
      inner:
        'relative rounded-2xl bg-white shadow dark:bg-gray-700 flex flex-col max-h-[95vh] overflow-y-auto',
    },
  },
};

type License = {
  code?: string | null;
  create_at?: string | null;
  id?: number | null;
  licenseCategory?: {
    id?: number | null;
    name?: string | null;
    requirement?: string | null;
  };
  licenseCategoryId?: number | null;
  name?: string | null;
  openShifts?: string | null;
};

const ShiftModal = (props: any) => {
  const [selectedRate, setSelectedRate] = useState<License[]>([]);
  const [requestLicense, setRequestLicense] = useState<License[]>([]);
  const [requestShiftCategory, setRequestShiftCategory] = useState<any>([]);
  const [currentFacilityId, setCurrentFacilityId] = useState<string>('');
  const [openTaskModal, setOpenTaskModal] = useState<string | undefined>();
  const [openShiftModal, setOpenShiftModal] = useState<string | undefined>();
  const [openSuccessModal, setOpenSuccessModal] = useState<
    string | undefined
  >();
  const [taskEdit, setTaskEdit] = useState<boolean>(false);
  const [shiftEdit, setShiftEdit] = useState<boolean>(false);
  const [shiftDuration, setShiftDuration] = useState<number>(0);
  const [showNotification, enableShowNotification] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    getValues,
    reset,
  } = useForm<CreateShift>({
    defaultValues: {
      requestType: '',
      task: '',
      shiftType: '',
      shiftStartDate: '',
      shiftEndDate: '',
      shiftStartTime: '',
      shiftEndTime: '',
    },
  });

  const createShift = useCreateShift({
    callbackOnSuccess: () => {
      setOpenSuccessModal('pop-up');
      reset({
        requestType: '',
        task: '',
        shiftType: '',
        shiftStartDate: '',
        shiftEndDate: '',
        shiftStartTime: '',
        shiftEndTime: '',
      });
    },
  });

  const onSubmit: SubmitHandler<CreateShift> = (data, event) => {
    event?.preventDefault();
    const inputEndDate = data.shiftEndDate;
    const [endYear, endMonth, endDay] = inputEndDate.split('-');
    const formattedEndDate = `${endMonth}-${endDay}-${endYear}`;
    const inputStartDate = data.shiftStartDate;
    const [startYear, startMonth, startDay] = inputStartDate.split('-');
    const formattedStartDate = `${startMonth}-${startDay}-${startYear}`;

    const formDetail = {
      licenseId: data.requestType,
      task: data.task,
      shiftCategoryId: data.shiftType,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      startTime: data.shiftStartTime + ':00',
      endTime: data.shiftEndTime + ':00',
      currentFacilityId: currentFacilityId,
    };

    createShift(formDetail);
  };

  const s = getValues('shiftStartDate');
  const e = getValues('shiftEndDate');
  useEffect(() => {
    if (s !== '' && e !== '') {
      const start = new Date(s);
      const end = new Date(e);
      const diffInMs = end.getTime() - start.getTime();
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      setShiftDuration(diffInDays + 1);
    } else {
      setShiftDuration(0);
    }
  }, [s, e, getValues]);

  const currentFacility = useAuthStore( useShallow((state: any) => state.currentFacility), );

  useEffect(() => {
    setCurrentFacilityId(currentFacility?.id);
  }, [currentFacility]);

  const request = useRequestLicenses(); // move this call to account toplevel
  const licenses = useAuthStore(useShallow((state: any) => state.licenses?.reverse()));
  useEffect(() => {
    request();

    if (licenses) {
      setRequestLicense(licenses);
    }
  }, []);

  const requestShift = useRequestShiftCategory();
  const shift = useAuthStore(useShallow((state: any) => state.shiftCategory));
  useEffect(() => {
    if (shift.length > 0) {
      setRequestShiftCategory(shift);
    } else {
      requestShift();
    }
  }, [shift]);

  const cat = getValues('shiftType');
  useEffect(() => {
    if (cat === '1') {
      setValue('shiftStartTime', shift[0]?.startTime?.slice(11, 16));
      setValue('shiftEndTime', shift[0]?.endTime?.slice(11, 16));
    } else if (cat === '2') {
      setValue('shiftStartTime', shift[1]?.startTime?.slice(11, 16));
      setValue('shiftEndTime', shift[1]?.endTime?.slice(11, 16));
    } else if (cat === '3') {
      setValue('shiftStartTime', shift[2]?.startTime?.slice(11, 16));
      setValue('shiftEndTime', shift[2]?.endTime?.slice(11, 16));
    }
  }, [cat, shift, setValue, getValues, register]);

  return (
    <>
      <Flowbite theme={{ theme: customTheme }}>
        <Modal
          position="top-right"
          size="lg"
          show={props.openModal === 'placement'}
          onClose={() => {
            reset({
              requestType: '',
              task: '',
              shiftType: '',
              shiftStartDate: '',
              shiftEndDate: '',
              shiftStartTime: '',
              shiftEndTime: '',
            });
            setTaskEdit(false);
            props.setOpenModal(undefined);
          }}
        >
          <Modal.Header>
            <div tw="flex items-center justify-center gap-6">
              <h1 tw="font-medium leading-8 text-[28px] text-[#010D26]">
                Create New Shift
              </h1>
              <div tw="flex items-center justify-center rounded-3xl font-normal text-xs leading-3 text-[#219653] p-[10px] bg-[#EAFAF1]">
                NEW
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div tw="flex flex-col items-start justify-center gap-6">
                <p tw="font-normal text-base underline text-[#3777F9]">
                  Click on each text field area to activate the typing mode
                </p>
                <div tw="flex w-full flex-col items-start border py-3 px-2 gap-[16px] rounded-[10px] border-[#F2F2F2] bg-[#FAFAFA]">
                  {/* license select */}
                  <div tw="flex w-full items-center gap-4">
                    <p tw="flex-shrink-0 font-medium text-xs text-[#010D26]">
                      Request Type
                    </p>
                    <FormSelect<CreateShift>
                      id="requestType"
                      name="requestType"
                      register={register}
                      rules={{
                        required: 'Request Type is required',
                      }}
                      errors={errors}
                      onChange={(event:any) => {
                        var rateFilter = [];
                        if (event.target.value != '') {

                          if (!isNaN(parseInt(event.target.value))) {
                             rateFilter = props?.facilityShiftRates?.filter(
                              (rate:any) => rate.licenseId == parseInt(event.target.value) && rate);

                            setSelectedRate(rateFilter)
                            enableShowNotification(rateFilter.length == 0)
                          }
                        }
                      }}
                    >
                      <option value="" selected>
                        Select
                      </option>
                      {requestLicense?.length > 0 &&
                        requestLicense?.map((license: any) => (
                          <option
                            key={license?.id}
                            value={license?.id?.toString()}
                          >
                            {license.name}
                          </option>
                        ))}
                    </FormSelect>
                  </div>
                  {/* task card */}
                  {taskEdit === false ? (
                    <div
                      onClick={() => {
                        setOpenTaskModal('pop-up');
                      }}
                      tw="flex w-full cursor-pointer flex-col items-center justify-center gap-4 border bg-white p-[14px] rounded-[6px] border-[#F2F2F2]"
                    >
                      <div tw="flex w-full items-center justify-between">
                        <p tw="font-medium text-base text-[#010D26]">Tasks:</p>
                        {getValues('task') === '' ? (
                          <p tw="flex items-center gap-1 font-normal leading-normal underline text-[13px] text-[#010D26]">
                            <span tw="mt-[2px]">
                              <Icons.InfoIcon />
                            </span>
                            Learn More
                          </p>
                        ) : (
                          <button
                            onClick={e => {
                              e.stopPropagation();
                              setTaskEdit(true);
                            }}
                            tw="flex items-center gap-1 font-normal text-sm text-[#00AEEF]"
                          >
                            <span tw="mt-px">
                              <Icons.EditIcon color="#00AEEF" />
                            </span>
                            Edit
                          </button>
                        )}
                      </div>
                      <ShiftFormTextArea<CreateShift>
                        id="task"
                        name="task"
                        placeholder="1. Eg. Medication Administration, Vital Sign Monitoring, Patient Scheme Assessments"
                        tw="w-full"
                        register={register}
                        rules={{
                          required: 'Tasks is required',
                          validate: value => {
                            return !!value.trim();
                          },
                        }}
                        errors={errors}
                      />
                    </div>
                  ) : (
                    <div tw="flex w-full flex-col items-start gap-4 border bg-white p-[24px] rounded-[6px] border-[#00AEEF]">
                      <label
                        tw="w-full font-medium text-base text-[#010D26]"
                        htmlFor="tasks"
                      >
                        Tasks:
                      </label>
                      <ShiftFormTextArea<CreateShift>
                        id="tasks"
                        name="task"
                        placeholder="1. Eg. Medication Administration, Vital Sign Monitoring, Patient Scheme Assessments"
                        register={register}
                        rules={{
                          required: 'Tasks is required',
                          validate: value => {
                            return !!value.trim();
                          },
                        }}
                        errors={errors}
                      />
                      <div tw="flex items-center justify-end gap-4 self-end">
                        <button
                          onClick={() => {
                            setTaskEdit(false);
                          }}
                          tw="flex items-center justify-center border w-[30px] h-[30px] rounded-[6px] border-[#B2EAFF] bg-[#F0FBFF]"
                        >
                          <Icons.MarkYes />
                        </button>
                        <button
                          onClick={e => {
                            setValue('task', '');
                            setTaskEdit(false);
                          }}
                          tw="flex items-center justify-center border w-[30px] h-[30px] rounded-[6px] border-[#B2EAFF] bg-[#F0FBFF]"
                        >
                          <Icons.MarkNo />
                        </button>
                      </div>
                    </div>
                  )}

                  {/* shift card */}
                  <div
                    onClick={() => {
                      if (shiftEdit === true) {
                        return;
                      }
                      setOpenShiftModal('pop-up');
                    }}
                    tw="w-full"
                  >
                    <div
                      css={[
                        shiftEdit === false
                          ? tw`pointer-events-none flex w-full cursor-pointer flex-col items-center justify-center gap-3 border bg-white p-[14px] rounded-[6px] border-[#F2F2F2]`
                          : tw`flex w-full cursor-pointer flex-col items-center justify-center gap-3 border bg-white p-[14px] rounded-[6px] border-[#F2F2F2]`,
                      ]}
                    >
                      <div tw="flex w-full items-center justify-between">
                        <p tw="font-medium text-base text-[#010D26]">
                          Shift Information:
                        </p>
                        <p tw="flex items-center gap-1 font-normal leading-normal underline text-[13px] text-[#010D26]">
                          <span tw="mt-[2px]">
                            <Icons.InfoIcon />
                          </span>
                          Learn More
                        </p>
                      </div>
                      <div tw="flex w-full flex-col items-start gap-4">
                        {/* shift duration (readonly) */}
                        <div tw="flex w-full items-center justify-between">
                          <p tw="flex items-center gap-3 font-medium text-sm text-[#010D26]">
                            <Icons.ShiftDuration /> Shift Duration
                          </p>
                          <input
                            tw="pointer-events-none rounded-md border bg-white px-4 font-medium text-sm w-[130px] border-[#F2F2F2] text-[#141414]"
                            type="text"
                            name="shiftDuration"
                            id="shiftDuration"
                            value={shiftDuration + '  ' + 'Day(s)'}
                          />
                        </div>
                        {/* shift type */}
                        <div tw="flex w-full items-center justify-between">
                          <p tw="flex items-center gap-3 font-medium text-sm text-[#010D26]">
                            <Icons.ShiftType /> Shift Type
                          </p>
                          <FormSelect<CreateShift>
                            id="shiftType"
                            name="shiftType"
                            register={register}
                            rules={{
                              required: 'Shift Type is required',
                            }}
                            errors={errors}
                          >
                            <option value="" selected>
                              Select
                            </option>
                            {requestShiftCategory?.length > 0 &&
                              requestShiftCategory?.map((category: any) => (
                                <option key={category?.id} value={category?.id}>
                                  {category?.name}
                                </option>
                              ))}
                          </FormSelect>
                        </div>

                        {/* shift date */}
                        <div tw="flex w-full items-center justify-between">
                          <p tw="flex flex-shrink-0 items-center gap-3 font-medium text-sm text-[#010D26]">
                            <Icons.ShiftDate /> Shift Date
                          </p>
                          <div tw="flex cursor-pointer items-start gap-2 font-medium text-[12px]">
                            <FormDateInput<CreateShift>
                              placeholder="Start date"
                              name="shiftStartDate"
                              id="shiftStartDate"
                              register={register}
                              rules={{
                                required: 'Start Date is required',
                              }}
                              errors={errors}
                            />
                            <FormDateInput<CreateShift>
                              placeholder="End date"
                              name="shiftEndDate"
                              id="shiftEndDate"
                              register={register}
                              rules={{
                                required: 'End Date is required',
                                validate: value => {
                                  const start = getValues('shiftStartDate');
                                  if (value <= start) {
                                    return 'End date cannot be less than or equal to start date';
                                  }
                                },
                              }}
                              errors={errors}
                            />
                          </div>
                        </div>

                        {/* shift time */}
                        <div tw="flex w-full items-center justify-between">
                          <p tw="flex items-center gap-3 font-medium text-sm text-[#010D26]">
                            <Icons.ShiftTime /> Shift Time
                          </p>
                          {/* the time format(12/24 hours) is solely dependent on your system time settings */}
                          <div tw="relative flex cursor-pointer items-center gap-2 font-medium text-[12px]">
                            <FormTimeInput<CreateShift>
                              placeholder="Start time"
                              name="shiftStartTime"
                              id="shiftStartTime"
                              register={register}
                              rules={{
                                required: 'Start time is required',
                              }}
                              errors={errors}
                            />
                            <FormTimeInput<CreateShift>
                              placeholder="End time"
                              name="shiftEndTime"
                              id="shiftEndTime"
                              register={register}
                              rules={{
                                required: 'End time is required',
                              }}
                              errors={errors}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div tw="mt-8 flex w-full items-center justify-end gap-[36px]">
                  <button
                    css={[
                      tw`flex items-center justify-start font-medium border-[0.5px] border-[#E0E0E0] rounded-[16px] px-[24px] py-[10px] text-[12px] text-[white]`,
                      selectedRate.length == 0 && tw`bg-[#AEAEAE]`,
                      selectedRate.length > 0 && tw`bg-[#00AEEF]`
                    ]}
                    disabled={selectedRate.length == 0}
                    onClick={(event:any) => {
                      // event.preventDefault();
                    }}
                    type="submit"

                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </Flowbite>
      <TaskInfoModal
        openTaskModal={openTaskModal}
        setOpenTaskModal={setOpenTaskModal}
        setTaskEdit={setTaskEdit}
      />
      <ShiftInfoModal
        openShiftModal={openShiftModal}
        setOpenShiftModal={setOpenShiftModal}
        setShiftEdit={setShiftEdit}
      />
      <SuccessModal
        openSuccessModal={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
        header="New Shift Created Successfully"
        body="We would notify you when a professional apply for this shift for your review."
        button="View all Shifts"
        setOpenModal={props.setOpenModal}
      />
      <NotificationModal showModal={showNotification} visibility={() => enableShowNotification(false)}>
        <div tw="flex w-full flex-col items-center text-center gap-[35px]">
          <p tw="font-medium text-base text-[#4D4D4D] leading-[26px]">
            You haven't set any rates for the selected role yet.
            Please click on the <span tw="underline underline-offset-4 text-[#000]">Create New Shift Rate</span> button in the <span tw="italic">Facility Rate</span> section of your
            <a tw="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 mx-1" href="/account">Account</a> to do so.
          </p>
          <div tw="flex items-center justify-center gap-4">
            <button
              onClick={() => enableShowNotification(false)}
              tw="flex items-center justify-start font-medium border-[0.5px] border-[#E0E0E0] bg-[#00AEEF] rounded-[16px] px-[24px] py-[10px] text-[12px] text-[white]"
            >
              Close
            </button>

          </div>
        </div>
      </NotificationModal>
    </>
  );
};

export default ShiftModal;
