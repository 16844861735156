/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import type { CustomFlowbiteTheme } from 'flowbite-react';
import { Modal, Spinner, Flowbite, Progress, Toast } from 'flowbite-react';
import { useShallow } from 'zustand/react/shallow';
import { useAppStore } from '@/store/client';
import errorIcon from '@/assets/svg/info-circle.svg';
import successIcon from '@/assets/svg/tick-circle.svg';
import { useEffect, useState } from 'react';

const TOAST_DURATION = 5;

const customTheme: CustomFlowbiteTheme = {
  modal: {
    root: {
      base: 'fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full backdrop-blur-sm ',
      sizes: {
        xs: 'max-w-[11rem] outline-none',
      },
    },
    content: {
      inner:
        'relative rounded-2xl bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh]',
    },
  },
  spinner: {
    color: {
      primary: 'fill-primary-500',
    },
    size: {
      '4xl': 'w-24 h-24',
    },
  },
};

const successProgressTheme: CustomFlowbiteTheme = {
  progress: {
    base: 'w-full overflow-hidden rounded-full bg-[#219653]',
    bar: 'rounded-full text-center font-medium leading-none text-cyan-300 dark:text-cyan-100 space-x-2 ease-linear duration-[1s]',
    color: {
      default: 'bg-[#D5F6E3]',
    },
    size: {
      xs: 'h-1',
      sm: 'h-1.5',
      md: 'h-2.5',
      lg: 'h-4',
      xl: 'h-6',
    },
  },
  toast: {
    root: {
      base: 'flex w-full max-w-[465px] items-center relative overflow-hidden rounded-xl bg-[#27AE60] p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400',
      closed: 'opacity-0 ease-out',
    },
    toggle: {
      base: '-mx-1.5 -my-1.5 ml-auto inline-flex h-11 w-11 ml-3 hover:bg-white [&>svg]:hover:fill-[#27AE60] rounded-lg p-1.5 text-gray-400 hover:text-gray-900 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white',
      icon: 'h-8 w-8 shrink-0 fill-white',
    },
  },
};
const errorProgressTheme: CustomFlowbiteTheme = {
  progress: {
    base: 'w-full overflow-hidden rounded-full bg-[#FF3F30]',
    bar: 'rounded-full text-center font-medium leading-none text-cyan-300 dark:text-cyan-100 space-x-2 ease-linear duration-[1s]',
    color: {
      default: 'bg-[#DF948B]',
    },
    size: {
      xs: 'h-1',
      sm: 'h-1.5',
      md: 'h-2.5',
      lg: 'h-4',
      xl: 'h-6',
    },
  },
  toast: {
    root: {
      base: 'flex w-full max-w-[465px] items-center relative overflow-hidden rounded-xl bg-[#E81313] p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400 ',
      closed: 'opacity-0 ease-out',
    },
    toggle: {
      base: '-mx-1.5 -my-1.5 ml-auto inline-flex h-11 w-11 ml-3 hover:bg-white [&>svg]:hover:fill-[#E81313] rounded-lg p-1.5 text-gray-400 hover:text-gray-900 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white',
      icon: 'h-8 w-8 shrink-0 fill-white',
    },
  },
};

const FeedbackManager = () => {
  const { loading, toastMessage, toastType, pageTitle } = useAppStore(
    useShallow(state => ({
      loading: state.loading,
      toastMessage: state.toastMessage,
      toastType: state.toastType,
      pageTitle: state.pageTitle,
    })),
  );

  const resetToast = useAppStore(state => state.resetToast);
  const hideLoading = useAppStore(state => state.hideLoading);

  const getProgressTheme = () =>
    toastType === 'success' ? successProgressTheme : errorProgressTheme;

  useEffect(() => {
    if (toastMessage) {
      setCountdown(TOAST_DURATION);
      setIsPaused(false);
    }
  }, [toastMessage]);

  useEffect(() => {
    const appName = 'Careitator Facility';
    document.title = pageTitle ? `${pageTitle} - ${appName}` : appName;
  }, [pageTitle]);

  const [countdown, setCountdown] = useState<number>(0);
  const [isPaused, setIsPaused] = useState<boolean>(true);

  useEffect(() => {
    if (loading) {
      hideLoading();
    }
    resetToast();
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      resetToast();
    }
    if (countdown === 0 || isPaused) return;

    if (!toastMessage) {
      setCountdown(0);
    }

    const interval = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown, isPaused]);

  return (
    <>
      <Flowbite theme={{ theme: { ...customTheme, ...getProgressTheme() } }}>
        <Modal show={loading} size="xs">
          <Modal.Body tw="">
            <Spinner aria-label="Loading spinner" size="4xl" color="primary" />
          </Modal.Body>
        </Modal>
        {toastMessage?.length > 0 && (
          <div
            tw="fixed top-7 right-8"
            onMouseEnter={() => {
              setIsPaused(true);
            }}
            onMouseLeave={() => {
              setIsPaused(false);
            }}
          >
            <Toast>
              {toastType === 'error' && (
                <img
                  src={errorIcon}
                  alt="Warning icon"
                  tw="h-[49px] w-[49px]"
                />
              )}
              {toastType === 'success' && (
                <img
                  src={successIcon}
                  alt="Success icon"
                  tw="h-[49px] w-[49px]"
                />
              )}
              <div
                tw="border-r py-4 pl-4 pr-6 font-medium text-base text-white leading-relaxed"
                css={[
                  toastType === 'error' && tw`border-[#DF948A]`,
                  toastType === 'success' && tw`border-[#61C454]`,
                ]}
              >
                {toastMessage}
              </div>

              <div tw="absolute bottom-0 left-0 w-full">
                <Progress
                  color="default"
                  progress={
                    countdown > 0
                      ? 100 -
                        ((TOAST_DURATION - countdown) / TOAST_DURATION) * 100
                      : 100
                  }
                  size="xs"
                  tw="rotate-180"
                />
              </div>
              <Toast.Toggle
                onDismiss={() => setCountdown(0)}
                tw="cursor-pointer"
              />
            </Toast>
          </div>
        )}
      </Flowbite>
    </>
  );
};

export default FeedbackManager;
