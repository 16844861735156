import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useNavigate, useRoutes } from 'react-router-dom';
import routes from './lib/routes';
import { FeedbackManager } from '@/components';
import globalRouter from './utils/global-router';
import { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { storage } from './utils';
import { RoutePath } from './lib/constants/route-path';
import { TokenManager } from './utils/helper';
import {ZohoSalesIQ} from "@/components/zoho";

const queryClient = new QueryClient();

function App() {
  const content = useRoutes(routes);

  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  TokenManager.scheduleLogout();

  return (
    <QueryClientProvider client={queryClient}>
      {content}
      <ReactQueryDevtools />
      <FeedbackManager />
      {process.env.NODE_ENV == "production" && <ZohoSalesIQ widgetCode={process.env.REACT_APP_ZOHO_SALESIQ_CODE} />}
    </QueryClientProvider>
  );
}

export default App;
