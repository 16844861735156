import type { RouteObject } from 'react-router';
import { Suspense, lazy } from 'react';
import { Loading } from '@/components';
import { AuthLayout, MainLayout } from '@/layouts';
import { Navigate } from 'react-router-dom';
import { RoutePath } from '../constants/route-path';

// eslint-disable-next-line react/display-name
const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) => (
  <Suspense fallback={<Loading />}>
    <Component {...props} />
  </Suspense>
);

// *  AUTHENTICATION PAGES
const Login = Loadable(lazy(() => import('../../views/auth/login')));
const SignUp = Loadable(lazy(() => import('../../views/auth/sign-up')));
const ForgotPassword = Loadable(
  lazy(() => import('../../views/auth/forgot-password')),
);
const ResetPassword = Loadable(
  lazy(() => import('../../views/auth/reset-password')),
);
const VerifyOTP = Loadable(lazy(() => import('../../views/auth/verify-otp')));

// * PROTECTED PAGES
const Dashboard = Loadable(lazy(() => import('../../views/dashboard')));
const Bookings = Loadable(lazy(() => import('../../views/bookings')));
const BookingsDetail = Loadable(
  lazy(() => import('../../views/bookings/bookings-detail')),
);
const ShiftManagement = Loadable(
  lazy(() => import('../../views/shift-management')),
);
const Timesheet = Loadable(lazy(() => import('../../views/timesheet')));
const Account = Loadable(lazy(() => import('../../views/account')));
const Support = Loadable(lazy(() => import('../../views/support')));

const routes: RouteObject[] = [
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: RoutePath.login,
        element: <Login />,
      },
      {
        path: RoutePath.signup,
        element: <SignUp />,
      },
      {
        path: RoutePath.forgotPassword,
        element: <ForgotPassword />,
      },
      {
        path: RoutePath.verifyOTP,
        element: <VerifyOTP />,
      },
      {
        path: RoutePath.resetPassword,
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Navigate replace to={RoutePath.dashboard} />,
      },
      {
        path: RoutePath.dashboard,
        element: <Dashboard />,
      },
      {
        path: 'bookings',
        element: <Bookings />,
      },
      {
        path: '/bookings/details/:id',
        element: <BookingsDetail />,
      },
      {
        path: 'shift-management',
        element: <ShiftManagement />,
      },
      {
        path: 'timesheet',
        element: <Timesheet />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'support',
        element: <Support />,
      },
    ],
  },
];

export default routes;
