import jwt_decode from 'jwt-decode';
import { storage } from '@/utils';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { RoutePath } from '@/lib/constants/route-path';

export const Regex = {
  email: {
    value: new RegExp('^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$', 'ig'),
    message: 'Enter a valid email address.',
  },
  phone: {
    value: new RegExp(/^\+\d{3} \d{3} \d{3} \d{4}$/),
    message: 'Enter a valid phone number.',
  },
};

export const ErrorManager = {
  getMessage: (error: any) => {
    return error?.response?.data?.message ?? 'An error occurred';
  },
};

export const TokenManager = {
  scheduleLogout: () => {
    const navigate = useNavigate();
    const token = storage.getToken();

    useEffect(() => {
      let timerRef: any = null;

      if (!token) {
        return;
      }

      const decoded = jwt_decode(token) as any;

      const expiryTime = new Date(decoded.exp * 1000).getTime();
      const currentTime = new Date().getTime();

      const timeout = expiryTime - currentTime;

      const onExpire = () => {
        storage.clearToken();
        navigate(RoutePath.login);
      };

      if (timeout > 0) {
        timerRef = setTimeout(onExpire, timeout);
      } else {
        onExpire();
      }

      return () => {
        clearTimeout(timerRef);
      };
    }, [navigate, token]);
  },
};
