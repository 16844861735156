/** @jsxImportSource @emotion/react */
import 'twin.macro';
import backIcon from '@/assets/svg/caret-left.svg';
import { useNavigate } from 'react-router-dom';

const Back = () => {
  const navigate = useNavigate();

  return (
    <button
      tw="flex items-center gap-1 font-medium text-primary text-xl"
      onClick={e => {
        navigate(-1);
      }}
    >
      <img src={backIcon} alt="Back icon" tw="h-6 w-6" />
      Back
    </button>
  );
};

export default Back;
