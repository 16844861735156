/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { FC } from 'react';
import { Button } from '@/components';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '@/lib/constants/route-path';
import { storage } from '@/utils';

type LogoutLinkProps = {
  label: string;
  icon: string;
};

const LogoutLink: FC<LogoutLinkProps> = ({ label, icon }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    storage.clearToken();
    navigate(RoutePath.login);
  };

  return (
    <Button
      tw="mt-auto flex w-full gap-6 bg-transparent px-6 py-5 text-primary text-base hover:font-semibold"
      onClick={handleLogout}
    >
      <img src={icon} alt="" tw="" />
      {label}
    </Button>
  );
};

export default LogoutLink;
