import React, { useEffect } from 'react';
import get from 'lodash.get';
import {
  RegisterOptions,
  DeepMap,
  FieldError,
  UseFormRegister,
  Path,
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select, { SelectProps } from '../../select';

export type FormSelectProps<TFormValues> = {
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  register?: UseFormRegister<any>;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
} & Omit<SelectProps, 'name'>;

const FormSelect = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  children,
  ...props
}: FormSelectProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div aria-live="polite">
      <Select
        name={name}
        aria-invalid={hasError}
        hasError={hasError}
        required={!!rules?.required}
        {...(register && register(name, rules))}
        {...props}
      >
        {children}
      </Select>
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name as any}
          render={({ message }) => (
            <p className="mt-1 text-left text-sm text-red-600">{message}</p>
          )}
        />
      )}
    </div>
  );
};

export default FormSelect;
