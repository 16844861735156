/** @jsxImportSource @emotion/react */
import { PropsWithChildren } from 'react';
import tw, { css } from 'twin.macro';

type ButtonProps = PropsWithChildren & {
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
};

const Button = ({
  children,
  className,
  disabled,
  type,
  onClick,
}: ButtonProps) => (
  <button
    tw="w-full bg-primary p-6 font-medium text-white text-base rounded-[20px]"
    css={[disabled && tw`cursor-not-allowed opacity-30`]}
    className={className}
    onClick={disabled ? undefined : onClick}
    type={type}
    disabled={disabled}
  >
    {children}
  </button>
);

export default Button;
