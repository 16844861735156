import { RoutePath } from '@/lib/constants/route-path';
import authService from '@/lib/services/auth-service';
import { useAppStore, useAuthStore } from '@/store/client';
import { storage } from '@/utils';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const useSignup = () => {
  const navigate = useNavigate();
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);
  const setOtpPrefix = useAuthStore(state => state.setOtpPrefix);
  const clearEmail = useAuthStore(state => state.clearEmail);

  const mutation = useMutation(authService.signup, {
    onMutate() {
      showLoading();
    },
    onSuccess({ otp, token }) {
      storage.saveToken(token);
      clearEmail();
      setOtpPrefix(otp);
      navigate(RoutePath.verifyOTP);
    },
    onError: error => {
      showErrorToast(error as any);
    },
    onSettled: () => {
      hideLoading();
    },
  });

  return mutation;
};

export const useAddAccount = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: Function;
}) => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showSuccessToast = useAppStore(state => state.showSuccessToast);
  const showErrorToast = useAppStore(state => state.showErrorToast);
  const setOtpPrefix = useAuthStore(state => state.setOtpPrefix);
  const clearEmail = useAuthStore(state => state.clearEmail);

  const { mutate: addAccountMutation } = useMutation(authService.addAccount, {
    onMutate() {
      showLoading();
    },
    onSuccess(data) {
      showSuccessToast(data?.data);
      callbackOnSuccess();
    },
    onError: error => {
      console.log(error);
      showErrorToast(error as any);
    },
    onSettled: () => {
      hideLoading();
    },
  });

  return addAccountMutation;
};
