/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';
import { FC } from 'react';
import { To, NavLink } from 'react-router-dom';

type MenuLinkProps = {
  to: To;
  label: string;
  icon: string;
  active?: boolean;
  activeIcon?: string;
};

const MenuLink: FC<MenuLinkProps> = ({
  to,
  label,
  icon,
  active,
  activeIcon,
}) => (
  <NavLink to={to} tw="w-full">
    {({ isActive }) => (
      <div
        tw="flex w-full cursor-pointer gap-6 px-6 py-5 text-sapphire-200 text-base hover:font-semibold"
        css={[
          isActive &&
            tw`border-primary border-r-4 bg-sapphire-900 font-semibold text-white`,
        ]}
      >
        <img src={isActive ? activeIcon : icon} alt="" tw="" />
        {label}
      </div>
    )}
  </NavLink>
);

export default MenuLink;
