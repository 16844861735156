import { QueryKey } from '@/lib/constants/query-keys';

export { default as axios } from './axios-instance';

export const storage = {
  getToken: () => {
    const key = localStorage.getItem(QueryKey.token);
    if (key) {
      return JSON.parse(key);
    } else {
      return null;
    }
  },
  saveToken: (token: string) =>
    localStorage.setItem(QueryKey.token, JSON.stringify(token)),
  clearToken: () => localStorage.removeItem(QueryKey.token),
};
