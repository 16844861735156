/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { Modal } from 'flowbite-react';

const TaskInfoModal = ({
  setOpenTaskModal,
  openTaskModal,
  setTaskEdit,
}: any) => {
  return (
    <>
      <Modal
        size="md"
        dismissible
        popup
        show={openTaskModal === 'pop-up'}
        onClose={() => setOpenTaskModal(undefined)}
      >
        <Modal.Body>
          <div tw="flex w-full flex-col items-center pt-8 text-center gap-[20px]">
            <h1 tw="text-center font-normal leading-normal text-[24px] text-[#181818]">
              TASKS
            </h1>
            <p tw="text-center font-light text-base text-[#4F4F4F]">
              A "task" refers to a specific duty or responsibility that needs to
              be performed by a healthcare professional during their shift at a
              facility. These tasks can encompass a wide range of activities,
              such as patient care, medical procedures, administrative duties,
              or any other responsibilities that are essential for the proper
              functioning of the healthcare facility. Specifying task
              information when creating a shift helps ensure that the
              professional assigned to that shift is fully aware of their
              responsibilities and can effectively contribute to the facility's
              operations and patient care.
            </p>
            <button
              onClick={() => {
                setOpenTaskModal(undefined);
                setTaskEdit(true);
              }}
              tw="flex w-full items-center justify-center font-normal text-white p-[10px] rounded-[52px] bg-[#00AEEF] text-[20px]"
            >
              Yes, I understand now
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TaskInfoModal;
