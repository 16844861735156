/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { Modal } from 'flowbite-react';
import React, {ReactNode} from "react";

type NotificationModalProps = {
  showModal: boolean,
  visibility: Function
  children?: ReactNode
}
const NotificationModal: React.FC<NotificationModalProps> = ({showModal, visibility, children}:NotificationModalProps) => {

  return (
    <>
      <Modal
        size="md"
        dismissible
        popup
        show={showModal}
        onClose={() => visibility(false)}
      >
        <Modal.Header />
        <Modal.Body>
          {children? children: '<></>'}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotificationModal;
