/** @jsxImportSource @emotion/react */
import 'twin.macro';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import React from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';
import Dots from './dots';
import { Children } from 'react';

// Define the props
type Props = PropsWithChildren &
  EmblaOptionsType & { className?: any; dotType?: 'outlined' | 'default' };

const Carousel = ({ children, className, dotType, ...options }: Props) => {
  // 1. useEmblaCarousel returns a emblaRef and we must attach the ref to a container.
  // EmblaCarousel will use that ref as basis for swipe and other functionality.
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({ delay: 5000 }),
  ]);

  // We need to track the selectedIndex to allow this component to re-render in react.
  // Since emblaRef is a ref, it won't re-render even if there are internal changes to its state.
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    function selectHandler() {
      // selectedScrollSnap gives us the current selected index.
      const index = emblaApi?.selectedScrollSnap();
      setSelectedIndex(index || 0);
    }

    emblaApi?.on('select', selectHandler);
    // cleanup
    return () => {
      emblaApi?.off('select', selectHandler);
    };
  }, [emblaApi]);

  const length = Children.count(children);

  return (
    // Attach ref to a div
    // 2. The wrapper div must have overflow:hidden
    <div tw="relative">
      <div tw="overflow-hidden" className={className} ref={emblaRef}>
        {/* 3. The inner div must have a display:flex property */}
        {/* 4. We pass the children as-is so that the outside component can style it accordingly */}
        <div tw="flex h-full">
          {Children.map(children, child => (
            <div tw="flex-[0_0_100%]">{child}</div>
          ))}
        </div>
      </div>
      <Dots itemsLength={length} selectedIndex={selectedIndex} type={dotType} />
    </div>
  );
};

export default Carousel;
