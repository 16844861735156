'use client';
import React, {useEffect } from "react";

// Zoho Sales IQ widget code
let execCleanUp = false;
export default function ZohoSalesIQ({widgetCode, ...props}: any) {

    useEffect(() => {
        if (!execCleanUp) {
            const script = document.createElement('script');
            const url = 'https://salesiq.zoho.com/widget';
            script.setAttribute("type", "text/javascript");
            script.setAttribute("id", "scriptid");
            let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`
            script.appendChild(document.createTextNode(code));
            document.body.appendChild(script);
        }
        // return () => {
        //     document!.getElementById('scriptid')!.remove();
        //     document!.getElementById('zsiqscript')!.remove();
        // }
        return () => { execCleanUp = true }
    })

    // const showChat = () => {
    //     document!.getElementById('zsiq_float')!.classList!.remove('d-none');
    //     document!.getElementById('zsiq_agtpic')!.click();
    // }

    return (
        <></>
    )
}


