import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppStore, useAuthStore } from '@/store/client';
import type {
  IRequestFacilities,
  IRequestOTPPrefix,
  IUseLogin,
  IUseLogout,
  LoginResponse,
  IRequestLicenses,
  IRequestShiftCategory,
} from '@/types';
import authService from '@/lib/services/auth-service';
import { QueryKey } from '@/lib/constants/query-keys';
import { useCallback } from 'react';
import { storage } from '@/utils';
import { RoutePath } from '@/lib/constants/route-path';
import { Facilities, Licenses, ShiftCategory } from '@/store/client/types';
import {
  IApproveTimesheet, IGetShiftRate,
  IRateProfessional,
  IRequestBookingList,
  IRequestBookingListDetail,
  IRequestDashboard,
  IRequestShiftList,
  IRequestTimesheetList,
  IRequestTimesheetQR, IShiftRate,
  IUseChangePassword,
  IUseCreateShift,
  IUseDeleteProfileUpload,
  IUseDeleteShift,
  IUseDeleteUpload,
  IUseEditShift,
  IUseGetRatingTags,
  IUseGetUpload,
  IUsePostAccount,
  IUseUpdateFacility,
  IUseUploadPhoto,
  IUseUploadProfilePhoto,
  IValidateBooking,
} from '@/types/store';
import {
  ApproveTimesheetResponse,
  BookingList,
  BookingListDetail,
  ChangePasswordResponse,
  CreateShiftResponse,
  DashboardData,
  DeleteProfileUploadResponse,
  DeleteUploadResponse,
  EditShiftResponse,
  GetUploadResponse,
  PostAccountResponse,
  RateProfessionalResponse,
  RatingTags,
  ShiftList, ShiftRateResponse,
  TimesheetList,
  TimesheetQRData,
  UpdateFacilityResponse,
  UploadPhotoResponse,
  ValidateBookingResponse,
} from '@/lib/services/auth-service/types';
import {useShallow} from "zustand/esm/react/shallow";
import {ShiftRateRequest} from "@/types/service";

export const useLogin = (): IUseLogin => {
  const navigate = useNavigate();
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);

  const { mutate: loginMutation } = useMutation<
    LoginResponse,
    unknown,
    { email: string; password: string },
    unknown
  >(({ email, password }) => authService.login(email, password), {
    onMutate: ({ email }) => {
      showLoading();
    },
    onSuccess: ({ isVerified, token }) => {
      storage.saveToken(token);
      // console.log('testing::: ', token);
      if (isVerified) {
        navigate(RoutePath.dashboard);
      } else {
        navigate(RoutePath.verifyOTP);
      }
    },
    onError: () => {
      showErrorToast(
        'Invalid login details, try again or go back and create a new account.',
      );
    },
    onSettled: () => {
      hideLoading();
    },
  });

  return loginMutation;
};

export const useLogout = (): IUseLogout => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSignOut = useCallback(() => {
    queryClient.setQueriesData([QueryKey.authUser], null);
    navigate(RoutePath.login);
  }, [navigate, queryClient]);

  return onSignOut;
};

export const useRequestOTP = (): IRequestOTPPrefix => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);
  const setOtpPrefix = useAuthStore(state => state.setOtpPrefix);
  const navigate = useNavigate();

  const { mutate: requestOTPPrefixMutation } = useMutation<
    string,
    unknown,
    void,
    unknown
  >(() => authService.requestOTP(), {
    onMutate: () => {
      showLoading();
    },
    onSuccess: data => {
      setOtpPrefix(data);
    },
    onError: () => {
      showErrorToast('Unable to retrieve OTP.');
      navigate(RoutePath.login);
    },
    onSettled: () => {
      hideLoading();
    },
  });

  return requestOTPPrefixMutation;
};

export const useVerifyOTP = ({
  callbackOnError,
}: {
  callbackOnError: Function;
}) => {
  const navigate = useNavigate();
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);

  const mutation = useMutation(authService.verifyOTP, {
    onMutate() {
      showLoading();
    },
    onSuccess({ token }) {
      console.log('new token on useVerifyOTP: ', token);
      storage.saveToken(token);
      navigate(RoutePath.dashboard);
    },
    onError: () => {
      showErrorToast('Invalid otp, check your email and try again.');
      callbackOnError();
    },
    onSettled: () => {
      hideLoading();
    },
  });
  return mutation;
};

export const useVerifyEmailAndOTP = ({
  callbackOnError,
}: {
  callbackOnError: Function;
}) => {
  const navigate = useNavigate();
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);

  const mutation = useMutation(authService.verifyEmailAndOTP, {
    onMutate: () => {
      showLoading();
    },
    onSuccess: ({ token }) => {
      storage.saveToken(token);
      navigate(RoutePath.resetPassword);
    },
    onError: () => {
      showErrorToast('Invalid otp, check your email and try again.');
      callbackOnError();
    },
    onSettled: () => {
      hideLoading();
    },
  });

  return mutation;
};

export const useForgotPassword = () => {
  const navigate = useNavigate();
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);
  const setOtpPrefix = useAuthStore(state => state.setOtpPrefix);
  const setEmail = useAuthStore(state => state.setEmail);
  const clearEmail = useAuthStore(state => state.clearEmail);

  const mutation = useMutation(authService.forgotPassword, {
    onMutate(email) {
      setEmail(email);
      showLoading();
    },
    onSuccess(otp) {
      setOtpPrefix(otp);
      navigate(RoutePath.verifyOTP);
    },
    onError: () => {
      clearEmail();
      showErrorToast();
    },
    onSettled: () => {
      hideLoading();
    },
  });

  return mutation;
};

export const useResetPassword = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: Function;
}) => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);

  const mutation = useMutation(authService.resetPassword, {
    onMutate() {
      showLoading();
    },
    onSuccess() {
      callbackOnSuccess();
    },
    onError: (error: any) => {
      const message = error?.response?.data?.message;
      showErrorToast(message);
    },
    onSettled: () => {
      hideLoading();
    },
  });
  return mutation;
};

export const useRequestFacilities = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: Function;
}): IRequestFacilities => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  // const setFacilities = useAuthStore(state => state.setFacilities);
  // const setCurrentFacility = useAuthStore(state => state.setCurrentFacility);
  // const clearFacilities = useAuthStore(state => state.clearFacilities);
  // const clearCurrentFacilities = useAuthStore(
  //   state => state.clearCurrentFacility,
  // );

  const { mutate: requestFacilitiesMutation } = useMutation<
    Facilities,
    unknown,
    void,
    unknown
  >(() => authService.requestFacilities(), {
    onMutate: () => {
      showLoading();
    },
    onSuccess: (data: any) => {
      // setFacilities(data);
      callbackOnSuccess(data);
    },
    onError: () => {
      console.log('get account info api error');
      // clearFacilities();
      // clearCurrentFacilities();
    },
    onSettled: () => {
      hideLoading();
    },
  });
  return requestFacilitiesMutation;
};

export const useRequestLicenses = (): IRequestLicenses => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const setLicenses = useAuthStore(state => state.setLicenses);
  // const clearLicenses = useAuthStore(state => state.clearLicenses);

  const { mutate: requestLicensesMutation } = useMutation<
    Licenses,
    unknown,
    void,
    unknown
  >(() => authService.requestLicenses(), {
    onMutate: () => {
      showLoading();
    },
    onSuccess: (data: any) => {
      setLicenses(data);
    },
    onError: () => {
      console.log('request licenses error');
      // clearLicenses();
    },
    onSettled: () => {
      hideLoading();
    },
  });
  return requestLicensesMutation;
};

export const useRequestShiftCategory = (): IRequestShiftCategory => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const setShiftCategory = useAuthStore(state => state.setShiftCategory);
  // const clearShiftCategory = useAuthStore(state => state.clearShiftCategory);

  const { mutate: requestShiftCategoryMutation } = useMutation<
    ShiftCategory,
    unknown,
    void,
    unknown
  >(() => authService.requestShiftCategory(), {
    onMutate: () => {
      showLoading();
    },
    onSuccess: (data: any) => {
      setShiftCategory(data);
    },
    onError: () => {
      console.log('Shift category error');
      // clearShiftCategory();
    },
    onSettled: () => {
      hideLoading();
    },
  });
  return requestShiftCategoryMutation;
};

export const useRequestShiftList = ({
  callbackOnSuccess,
  callbackOnError,
}: {
  callbackOnSuccess: Function;
  callbackOnError: Function;
}): IRequestShiftList => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  // const showErrorToast = useAppStore(state => state.showErrorToast);

  const { mutate: requestShiftListMutation } = useMutation<
    ShiftList,
    unknown,
    {
      currentFacilityId: string;
      status: string;
      startDate: string;
      endDate: string;
      selectShiftDuraton: string;
      selectShiftType: string;
    },
    unknown
  >(
    ({
      currentFacilityId,
      status,
      startDate,
      endDate,
      selectShiftDuraton,
      selectShiftType,
    }: {
      currentFacilityId: string;
      status: string;
      startDate: string;
      endDate: string;
      selectShiftDuraton: string;
      selectShiftType: string;
    }) =>
      authService.requestShiftList(
        currentFacilityId,
        status,
        startDate,
        endDate,
        selectShiftDuraton,
        selectShiftType,
      ),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        callbackOnSuccess(data);
      },
      onError: () => {
        callbackOnError();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );
  return requestShiftListMutation;
};

export const useCreateShift = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: Function;
}): IUseCreateShift => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);

  const { mutate: createShiftMutation } = useMutation<
    CreateShiftResponse,
    unknown,
    {
      licenseId: string;
      task: string;
      shiftCategoryId: string;
      startDate: string;
      endDate: string;
      startTime: string;
      endTime: string;
      currentFacilityId: string;
    },
    unknown
  >(
    ({
      licenseId,
      task,
      shiftCategoryId,
      startDate,
      endDate,
      startTime,
      endTime,
      currentFacilityId,
    }: any) =>
      authService.createShift(
        licenseId,
        task,
        shiftCategoryId,
        startDate,
        endDate,
        startTime,
        endTime,
        currentFacilityId,
      ),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: ({ message, isSuccessful }) => {
        callbackOnSuccess();
      },
      onError: () => {
        showErrorToast();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );

  return createShiftMutation;
};

export const useEditShift = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: Function;
}): IUseEditShift => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);
  const showSuccessToast = useAppStore(state => state.showSuccessToast);

  const { mutate: editShiftMutation } = useMutation<
    EditShiftResponse,
    unknown,
    {
      task: string;
      shiftId: string;
      currentFacilityId: string;
    },
    unknown
  >(
    ({ task, shiftId, currentFacilityId }: any) =>
      authService.editShift(task, shiftId, currentFacilityId),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: ({ message, isSuccessful }) => {
        showSuccessToast(message);
        callbackOnSuccess();
      },
      onError: () => {
        showErrorToast();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );

  return editShiftMutation;
};

export const useDeleteShift = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: Function;
}): IUseDeleteShift => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);
  const showSuccessToast = useAppStore(state => state.showSuccessToast);

  const { mutate: deleteShiftMutation } = useMutation<
    EditShiftResponse,
    unknown,
    {
      shiftId: string;
      currentFacilityId: string;
    },
    unknown
  >(
    ({ shiftId, currentFacilityId }: any) =>
      authService.deleteShift(shiftId, currentFacilityId),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: ({ message, isSuccessful }) => {
        showSuccessToast(message);
        callbackOnSuccess();
      },
      onError: () => {
        showErrorToast();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );

  return deleteShiftMutation;
};

export const useRequestDashboard = ({
  callbackOnSuccess,
  callbackOnError,
}: {
  callbackOnSuccess: Function;
  callbackOnError: Function;
}): IRequestDashboard => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);

  const { mutate: requestDashboardMutation } = useMutation<
    DashboardData,
    unknown,
    { currentFacilityId: string },
    unknown
  >(
    ({ currentFacilityId }: { currentFacilityId: string }) =>
      authService.requestDashboard(currentFacilityId),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        callbackOnSuccess(data);
      },
      onError: () => {
        callbackOnError();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );
  return requestDashboardMutation;
};

export const useRequestTimesheetQR = ({
  callbackOnSuccess,
  callbackOnError,
}: {
  callbackOnSuccess: Function;
  callbackOnError: Function;
}): IRequestTimesheetQR => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);

  const { mutate: requestTimesheetQRMutation } = useMutation<
    TimesheetQRData,
    unknown,
    { currentFacilityId: string },
    unknown
  >(
    ({ currentFacilityId }: { currentFacilityId: string }) =>
      authService.requestTimesheetQR(currentFacilityId),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        callbackOnSuccess(data);
      },
      onError: () => {
        callbackOnError();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );
  return requestTimesheetQRMutation;
};

export const useRequestTimesheetList = ({
  callbackOnSuccess,
  callbackOnError,
}: {
  callbackOnSuccess: Function;
  callbackOnError: Function;
}): IRequestTimesheetList => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);

  const { mutate: requestTimesheetList } = useMutation<
    TimesheetList,
    unknown,
    { currentFacilityId: string },
    unknown
  >(
    ({ currentFacilityId }: { currentFacilityId: string }) =>
      authService.requestTimesheetList(currentFacilityId),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        callbackOnSuccess(data);
      },
      onError: () => {
        callbackOnError();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );
  return requestTimesheetList;
};

export const usePostAccountInfo = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: Function;
}): IUsePostAccount => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showSuccessToast = useAppStore(state => state.showSuccessToast);
  const showErrorToast = useAppStore(state => state.showErrorToast);

  const { mutate: postAccountMutation } = useMutation<
    PostAccountResponse,
    unknown,
    {
      firstName: string;
      lastName: string;
      phoneNumber: string;
    },
    unknown
  >(
    ({ firstName, lastName, phoneNumber }: any) =>
      authService.postAccountInfo(firstName, lastName, phoneNumber),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        showSuccessToast(data?.message);
        callbackOnSuccess();
      },
      onError: () => {
        showErrorToast();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );

  return postAccountMutation;
};

export const useUpdateFacility = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: Function;
}): IUseUpdateFacility => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showSuccessToast = useAppStore(state => state.showSuccessToast);
  const showErrorToast = useAppStore(state => state.showErrorToast);

  const { mutate: updateFacilityMutation } = useMutation<
    UpdateFacilityResponse,
    unknown,
    {
      name: string;
      address: string;
      longitude: any;
      latitude: any;
      defaultFac: boolean;
      currentFacilityId: string;
    },
    unknown
  >(
    ({
      name,
      address,
      longitude,
      latitude,
      defaultFac,
      currentFacilityId,
    }: any) =>
      authService.updateFacility(
        name,
        address,
        longitude,
        latitude,
        defaultFac,
        currentFacilityId,
      ),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        showSuccessToast('Update successful');
        callbackOnSuccess();
      },
      onError: () => {
        showErrorToast();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );

  return updateFacilityMutation;
};

export const useUploadPhoto = ({
  callbackOnSuccess,
  callbackOnError,
}: {
  callbackOnSuccess: Function;
  callbackOnError: Function;
}): IUseUploadPhoto => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showSuccessToast = useAppStore(state => state.showSuccessToast);
  const showErrorToast = useAppStore(state => state.showErrorToast);

  const { mutate: uploadPhotoMutation } = useMutation<
    UploadPhotoResponse,
    unknown,
    {
      file: any;
      description: string;
      tag: string;
      currentFacilityId: string;
    },
    unknown
  >(
    ({ file, description, tag, currentFacilityId }: any) =>
      authService.uploadPhoto(file, description, tag, currentFacilityId),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        showSuccessToast('Upload successful');
        callbackOnSuccess();
      },
      onError: () => {
        showErrorToast();
        callbackOnError();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );

  return uploadPhotoMutation;
};

export const useGetUpload = ({
  callbackOnSuccess,
  callbackOnError,
}: {
  callbackOnSuccess: Function;
  callbackOnError: Function;
}): IUseGetUpload => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);

  const { mutate: getUploadMutation } = useMutation<
    GetUploadResponse,
    unknown,
    { currentFacilityId: string },
    unknown
  >(
    ({ currentFacilityId }: { currentFacilityId: string }) =>
      authService.getUpload(currentFacilityId),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        callbackOnSuccess(data);
      },
      onError: () => {
        callbackOnError();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );
  return getUploadMutation;
};

export const useDeleteUpload = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: Function;
}): IUseDeleteUpload => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);
  const showSuccessToast = useAppStore(state => state.showSuccessToast);

  const { mutate: deleteUploadMutation } = useMutation<
    DeleteUploadResponse,
    unknown,
    {
      tag: string;
      currentFacilityId: string;
    },
    unknown
  >(
    ({ tag, currentFacilityId }: any) =>
      authService.deleteUpload(tag, currentFacilityId),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: ({ message, isSuccessful }) => {
        showSuccessToast(message);
        callbackOnSuccess();
      },
      onError: () => {
        showErrorToast();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );

  return deleteUploadMutation;
};

export const useChangePassword = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: Function;
}): IUseChangePassword => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showSuccessToast = useAppStore(state => state.showSuccessToast);
  const showErrorToast = useAppStore(state => state.showErrorToast);

  const { mutate: changePasswordMutation } = useMutation<
    ChangePasswordResponse,
    unknown,
    {
      currentPassword: string;
      newPassword: string;
    },
    unknown
  >(
    ({ currentPassword, newPassword }: any) =>
      authService.changePassword(currentPassword, newPassword),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        showSuccessToast('Password changed successfully');
        callbackOnSuccess(data);
      },
      onError: () => {
        showErrorToast();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );

  return changePasswordMutation;
};

export const useUploadProfilePhoto = ({
  callbackOnSuccess, // callbackOnError,
}: {
  callbackOnSuccess: Function;
  // callbackOnError: Function;
}): IUseUploadProfilePhoto => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showSuccessToast = useAppStore(state => state.showSuccessToast);
  const showErrorToast = useAppStore(state => state.showErrorToast);

  const { mutate: uploadProfilePhotoMutation } = useMutation<
    any,
    unknown,
    {
      file: any;
    },
    unknown
  >(({ file }: any) => authService.uploadProfilePhoto(file), {
    onMutate: () => {
      showLoading();
    },
    onSuccess: data => {
      showSuccessToast('Upload successful');
      callbackOnSuccess();
    },
    onError: () => {
      showErrorToast();
      // callbackOnError();
    },
    onSettled: () => {
      hideLoading();
    },
  });

  return uploadProfilePhotoMutation;
};

export const useDeleteProfileUpload = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: Function;
}): IUseDeleteProfileUpload => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);
  const showSuccessToast = useAppStore(state => state.showSuccessToast);

  const { mutate: deleteProfileUploadMutation } = useMutation<
    DeleteProfileUploadResponse,
    unknown,
    void,
    unknown
  >(() => authService.deleteProfileUpload(), {
    onMutate: () => {
      showLoading();
    },
    onSuccess: ({ message, isSuccessful }) => {
      showSuccessToast(message);
      callbackOnSuccess();
    },
    onError: () => {
      showErrorToast();
    },
    onSettled: () => {
      hideLoading();
    },
  });

  return deleteProfileUploadMutation;
};

export const useRequestBookingList = ({
  callbackOnSuccess,
  callbackOnError,
}: {
  callbackOnSuccess: Function;
  callbackOnError: Function;
}): IRequestBookingList => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  // const showErrorToast = useAppStore(state => state.showErrorToast);

  const { mutate: requestBookingListMutation } = useMutation<
    BookingList,
    unknown,
    {
      currentFacilityId: string;
      // status: string;
      // startDate: string;
      // endDate: string;
      // selectShiftDuraton: string;
      // selectShiftType: string;
    },
    unknown
  >(
    ({
      currentFacilityId, // status,
      // endDate,
    } // startDate,
    // selectShiftDuraton,
    // selectShiftType,
    : {
      currentFacilityId: string;
      // status: string;
      // startDate: string;
      // endDate: string;
      // selectShiftDuraton: string;
      // selectShiftType: string;
    }) =>
      authService.requestBookingList(
        currentFacilityId,
        // status,
        // startDate,
        // endDate,
        // selectShiftDuraton,
        // selectShiftType,
      ),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        callbackOnSuccess(data);
      },
      onError: () => {
        callbackOnError();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );
  return requestBookingListMutation;
};

export const useRequestBookingListDetail = ({
  callbackOnSuccess,
  callbackOnError,
}: {
  callbackOnSuccess: Function;
  callbackOnError: Function;
}): IRequestBookingListDetail => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  // const showErrorToast = useAppStore(state => state.showErrorToast);

  const { mutate: requestBookingListDetailMutation } = useMutation<
    BookingListDetail,
    unknown,
    {
      currentFacilityId: string;
      openShiftId: string;
    },
    unknown
  >(
    ({
      currentFacilityId,
      openShiftId, // status,
      // selectShiftDuraton,
    } // endDate,
    // selectShiftType,
    // startDate,
    : {
      currentFacilityId: string;
      openShiftId: string;
    }) =>
      authService.requestBookingListDetail(
        currentFacilityId,
        openShiftId,
      ),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        callbackOnSuccess(data);
      },
      onError: () => {
        callbackOnError();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );
  return requestBookingListDetailMutation;
};

export const useValidateBooking = ({
  callbackOnSuccess,
  callbackOnError,
}: {
  callbackOnSuccess: Function;
  callbackOnError: Function;
}): IValidateBooking => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  // const showErrorToast = useAppStore(state => state.showErrorToast);

  const { mutate: validateBookingMutation } = useMutation<
    ValidateBookingResponse,
    unknown,
    {
      currentFacilityId: string;
      openShiftId: string;
      closeShift: boolean;
      approve: any;
      decline: any;
      // status: string;
      // startDate: string;
      // endDate: string;
      // selectShiftDuraton: string;
      // selectShiftType: string;
    },
    unknown
  >(
    ({
      currentFacilityId,
      openShiftId,
      closeShift,
      approve,
      decline, // status,
      // endDate,
    } // startDate,
    // selectShiftDuraton,
    // selectShiftType,
    : {
      currentFacilityId: string;
      openShiftId: string;
      closeShift: boolean;
      approve: any;
      decline: any;
      // status: string;
      // startDate: string;
      // endDate: string;
      // selectShiftDuraton: string;
      // selectShiftType: string;
    }) =>
      authService.validateBooking(
        currentFacilityId,
        openShiftId,
        closeShift,
        approve,
        decline,
        // status,
        // startDate,
        // endDate,
        // selectShiftDuraton,
        // selectShiftType,
      ),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        callbackOnSuccess(data);
      },
      onError: () => {
        callbackOnError();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );
  return validateBookingMutation;
};

export const useApproveTimesheet = ({
  callbackOnSuccess,
  callbackOnError,
}: {
  callbackOnSuccess: Function;
  callbackOnError: Function;
}): IApproveTimesheet => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);

  const { mutate: approveTimesheetMutation } = useMutation<
    ApproveTimesheetResponse,
    unknown,
    {
      currentFacilityId: string;
      openShiftAssignId: string;
      userId: string;
      approved: boolean;
    },
    unknown
  >(
    ({
      currentFacilityId,
      openShiftAssignId,
      userId,
      approved,
    }: {
      currentFacilityId: string;
      openShiftAssignId: string;
      userId: string;
      approved: boolean;
    }) =>
      authService.approveTimesheet(
        currentFacilityId,
        openShiftAssignId,
        userId,
        approved,
      ),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        callbackOnSuccess(data);
      },
      onError: (e) => {
        callbackOnError(e);
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );
  return approveTimesheetMutation;
};

export const useRatingProfessional = ({
  callbackOnSuccess,
  callbackOnError,
}: {
  callbackOnSuccess: Function;
  callbackOnError: Function;
}): IRateProfessional => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);

  const { mutate: rateProfessionalMutation } = useMutation<
    RateProfessionalResponse,
    unknown,
    {
      id: string;
      rating: number;
      review: string;
      tags: string;
    },
    unknown
  >(
    ({
      id,
      rating,
      review,
      tags,
    }: {
      id: string;
      rating: number;
      review: string;
      tags: string;
    }) => authService.rateProfessional(id, rating, review, tags),
    {
      onMutate: () => {
        showLoading();
      },
      onSuccess: data => {
        callbackOnSuccess(data);
      },
      onError: () => {
        callbackOnError();
      },
      onSettled: () => {
        hideLoading();
      },
    },
  );
  return rateProfessionalMutation;
};

export const useGetRatingTags = ({
  callbackOnSuccess,
}: {
  callbackOnSuccess: Function;
}): IUseGetRatingTags => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);

  const { mutate: getRatingTagsMutation } = useMutation<
    RatingTags[],
    unknown,
    void,
    unknown
  >(() => authService.getRatingTags(), {
    onMutate: () => {
      showLoading();
    },
    onSuccess: data => {
      callbackOnSuccess(data);
    },
    onError: () => {
      showErrorToast();
    },
    onSettled: () => {
      hideLoading();
    },
  });

  return getRatingTagsMutation;
};

export const useFacilityShiftRate = ({onSuccess}: {onSuccess: Function}): IGetShiftRate => {
  const showLoading = useAppStore(state => state.showLoading);
  const hideLoading = useAppStore(state => state.hideLoading);
  const showErrorToast = useAppStore(state => state.showErrorToast);

  const {mutate: facilityShiftRateMutation} = useMutation<
      ShiftRateResponse,
      unknown,
      { currentFacilityId: string },
      unknown
  >(({ currentFacilityId }: { currentFacilityId: string })  => authService.getShiftRate(currentFacilityId), {
    onMutate: () => {
      showLoading();
    },
    onSuccess: (data: any) => onSuccess(data),
    onError: () => {
      showErrorToast(
          'Facility shift rate loading error',
      );
    },
    onSettled: () => {
      hideLoading();
    },
  });
  return facilityShiftRateMutation;
};

